import { makeApiResource } from "api/makeApiResource";
import { apiFetcher } from "../api_client";

const basePath = "/admin/level_of_care_periods";

export const levelOfCarePeriodsApi = makeApiResource({
  baseQueryKey: ["level_of_care_periods"],
  getList: (queryParams?: any, _context?: any) =>
    apiFetcher<ApiDataResponse<LevelOfCarePeriod[]>>(basePath, { queryParams }),
});
