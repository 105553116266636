import * as React from "react";
import { Menu } from "antd";
import {
  TeamOutlined,
  NotificationOutlined,
  UserSwitchOutlined,
  CalendarOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";
import { Link, useMatch } from "react-router-dom";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import { ReactComponent as SidekiqIcon } from "assets/sidekiq-icon.svg";
import { ReactComponent as SwaggerIcon } from "assets/swagger-icon.svg";
import Icon from "@ant-design/icons";
import * as paths from "utils/paths";

type ExternalLinkLabelParams = {
  url: string;
  text: string;
};

const ExternalLinkLabel = ({ url, text }: ExternalLinkLabelParams) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

const menuItems: MenuItemType[] = [
  {
    label: <Link to={paths.groupAppointmentsPath}>Group Appointments</Link>,
    key: paths.groupAppointmentsPath,
    icon: <TeamOutlined />,
  },
  {
    label: <Link to={paths.appointmentsPath}>Appointments</Link>,
    key: paths.appointmentsPath,
    icon: <CarryOutOutlined />,
  },
  {
    label: <Link to={paths.notificationEventsPath}>Notification Events</Link>,
    key: paths.notificationEventsPath,
    icon: <NotificationOutlined />,
  },
  {
    label: <Link to={paths.usersPath}>Users</Link>,
    key: paths.usersPath,
    icon: <UserSwitchOutlined />,
  },
  {
    label: <Link to={paths.calendarSyncInfosPath}>Calendar Syncs</Link>,
    key: paths.calendarSyncInfosPath,
    icon: <CalendarOutlined />,
  },
  {
    label: (
      <ExternalLinkLabel
        url={`${process.env.REACT_APP_BASE_API_URL}/sidekiq`}
        text="Sidekiq"
      />
    ),
    key: "sidekiq",
    icon: (
      <Icon
        component={() => (
          <SidekiqIcon fill="currentColor" width="1em" height="1em" />
        )}
      />
    ),
  },
  {
    label: (
      <ExternalLinkLabel
        url={`${process.env.REACT_APP_BASE_API_URL}/swagger`}
        text="Swagger"
      />
    ),
    key: "swagger",
    icon: (
      <Icon
        component={() => (
          <SwaggerIcon fill="currentColor" width="1em" height="1em" />
        )}
      />
    ),
  },
];

export const TopNavBar = () => {
  const pathMatches = menuItems.reduce((result, item) => {
    if (useMatch(`/${item.key}/*`)) result.push(item.key);
    return result;
  }, [] as any[]);

  return (
    <Menu
      mode="horizontal"
      selectedKeys={pathMatches}
      items={menuItems}
      style={{ width: "100%" }}
    />
  );
};
