import * as React from "react";
import { setAuthorizationToken } from "../api/api_client/store";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";

export namespace Login {
  export const loader = ({ request }: { request: Request }) => {
    const url = new URL(request.url);
    const token = url.searchParams.get("token");

    if (token) setAuthorizationToken(token);
    return redirect("/");
  };
}

export default Login;
