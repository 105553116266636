import React from "react";
import { Button, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { therapyGroupsApi } from "api/resources/therapyGroups";
import { makeAction, makeLoader, useLoaderData } from "react-router-typesafe";
import { PageTitle } from "components/PageTitle";
import { Link, Outlet, redirect } from "react-router-dom";
import { notificationEventsPath, therapyGroupPath } from "utils/paths";
import FormattedDate from "components/FormattedDate";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "api/api_client";
import { notificationEventsApi } from "api/resources/notificationEvents";
import { UserNotifications } from "./UserNotifications";
import { toast } from "react-toastify";
import { handleErrorResponse } from "utils/loaderHelpers";
import { useForm } from "antd/es/form/Form";
import { NewNotificationEventForm } from "./NewNotificationEvent";

const columns: ColumnsType<NotificationEvent> = [
  {
    title: "Type",
    dataIndex: "event_type",
    key: "event_type",
  },
  {
    title: "Notification Count",
    dataIndex: "notification_count",
    key: "notification_count",
  },
  {
    title: "Created at",
    key: "created_at",
    dataIndex: "created_at",
    render: (value) => <FormattedDate value={value} format="long" />,
  },
];

export namespace NotificationEvents {
  export const action = makeAction(async ({ request }) =>
    notificationEventsApi.sendMessage
      .call(await request.json())
      .then(() => {
        toast.success("Notification Message sent successfully");
        queryClient.invalidateQueries(notificationEventsApi.getList.query());
        return redirect(notificationEventsPath);
      })
      .catch(handleErrorResponse)
  );

  export const loader = makeLoader(() =>
    queryClient.ensureQueryData(notificationEventsApi.getList.query())
  );

  export const Element = () => {
    const initialData = useLoaderData<typeof loader>();
    const {
      data: { data: notificationEvents },
    } = useQuery({
      ...notificationEventsApi.getList.query(),
      initialData,
      staleTime: 2 * 60 * 1000,
    });
    const [form] = useForm();
    const [createModalOpen, setCreateModalOpen] = React.useState(false);

    return (
      <>
        <PageTitle title="Notification Events">
          <Button type="primary" onClick={() => setCreateModalOpen(true)}>
            Create Notification
          </Button>
        </PageTitle>

        <Table
          columns={columns}
          dataSource={notificationEvents}
          size="small"
          pagination={{
            size: "default",
          }}
          rowKey="id"
          expandable={{
            expandedRowRender: (record) => (
              <UserNotifications notification_event_id={record.id} />
            ),
          }}
        />

        <Modal
          title="Send New Notification"
          okText="Send"
          onOk={() => form.submit()}
          onCancel={() => setCreateModalOpen(false)}
          open={createModalOpen}
        >
          <NewNotificationEventForm form={form} />
        </Modal>
      </>
    );
  };

  export const element = <Element />;
}

export default NotificationEvents;
