import React from "react";
import { $accessToken } from "../api/api_client/store";
import { Outlet, ShouldRevalidateFunction, useRouteError } from "react-router";
import { makeLoader, useLoaderData } from "react-router-typesafe";
import { Layout, Result, theme } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import mainLogo from "assets/logo.svg";
import cryingEmoji from "assets/sad-emoji.png";
import Sider from "antd/es/layout/Sider";
import { Breadcrumbs } from "components/Breadcrumbs";
import { TopNavBar } from "components/TopNavBar";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { queryClient } from "api/api_client";
import { profileApi } from "api/resources/profile";
import { useQuery } from "@tanstack/react-query";
import { useRefreshSession } from "hooks/useRefreshSession";

export namespace AppLayout {
  export const loader = makeLoader(() => {
    if (process.env.NODE_ENV !== "development")
      if (!$accessToken.get()) throw "You are not logged in";
    return queryClient.ensureQueryData(profileApi.get.query());
  });

  export const shouldRevalidate: ShouldRevalidateFunction = () => false;

  export const handle = {
    crumb: () => <Link to="/">Home</Link>,
  };

  export const Element = () => {
    const initialData = useLoaderData<typeof loader>();
    const {
      data: { data: profile },
    } = useQuery({
      ...profileApi.get.query(),
      initialData,
    });
    const {
      token: { colorBgContainer },
    } = theme.useToken();

    useRefreshSession();

    return (
      <Layout style={{ height: "100vh" }}>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            background: colorBgContainer,
            padding: "10px",
          }}
        >
          <Link to="/" style={{ display: "block" }}>
            <img
              src={mainLogo}
              style={{ marginRight: "10px", display: "block" }}
            />
          </Link>
          <TopNavBar />
          <span>{profile.email}</span>
        </Header>
        <Layout style={{ height: "100%" }}>
          <Sider style={{ background: "#FFF" }} />
          <Layout style={{ padding: "0 64px 24px 0", background: "#FFF" }}>
            <Breadcrumbs />
            <Content
              style={{
                margin: 0,
                minHeight: 280,
                background: "#FFF",
              }}
            >
              <Outlet />
            </Content>
          </Layout>
          <ToastContainer position="bottom-center" autoClose={3000} />
        </Layout>
      </Layout>
    );
  };

  export const element = <Element />;

  export const ErrorElement = () => {
    // TODO: Make better error handling
    const error = useRouteError();
    console.log(error); // TODO: Add Sentry logging

    const getResultFields = (error: any) => {
      // Add Sentry logging
      if (error instanceof Error)
        return {
          title: "Unknown error occurred",
          extra: <span>Contact system administrator</span>,
        };

      switch ((error as Response).status) {
        case 401:
          return {
            title: "You are not logged in",
            extra: <span>Log in through your SSO provider</span>,
          };
        case 404:
          return { title: "Not Found" };
        case 403:
          return { title: "Resource is Fodbidden" };
        case 500:
          return {
            title: "Server error has occurred",
            extra: <span>Contact system administrator</span>,
          };
        default:
          return {
            title: "Unknown error occurred",
            extra: <span>Contact system administrator</span>,
          };
      }
    };

    return (
      <Result
        icon={<img src={cryingEmoji} style={{ width: "150px" }} />}
        {...getResultFields(error)}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          msTransform: "translate(-50%, -50%)",
          transform: "translate(-50%, -50%)",
        }}
      />
    );
  };

  export const errorElement = <ErrorElement />;
}

export default AppLayout;
