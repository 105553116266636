import React, { ReactNode, useEffect, useState } from "react";
import { Select, SelectProps, Skeleton, Spin } from "antd";
import useDebounce from "hooks/useDebounce";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

type SearchInputProps<T, QErr> = {
  queryOptionsFn: (
    value: string
  ) => UseQueryOptions<ApiDataResponse<T[]>, QErr>;
  paramsMethod: (obj: T) => { label: ReactNode; value: any };
  additionalOptions?: { label: ReactNode; value: any }[];
  excludeOptions?: (obj: T) => boolean;
} & SelectProps;

export const SearchInput = <T, QErr>({
  queryOptionsFn,
  paramsMethod,
  excludeOptions,
  additionalOptions,
  ...selectProps
}: SearchInputProps<T, QErr>) => {
  const [curValue, setCurValue] = useState();
  const debouncedValue = useDebounce<any>(curValue, 500);
  const [persistedData, setPersistedData] = useState<T[]>([]);
  const { data, isFetching, error } = useQuery({
    ...queryOptionsFn(debouncedValue),
    retry: false,
  });

  useEffect(() => {
    if (data?.data) {
      if (excludeOptions) {
        setPersistedData(data.data.filter((obj) => !excludeOptions(obj)));
      } else {
        setPersistedData(data.data);
      }
    }
  }, [data]);

  const handleSearch = (value: any) => {
    setCurValue(value != "" ? value : undefined);
  };

  return (
    <Select
      showSearch
      loading={isFetching}
      allowClear
      onSearch={handleSearch}
      notFoundContent={
        isFetching ? (
          <Skeleton
            active
            paragraph={{ rows: 3, width: "100%" }}
            title={false}
          />
        ) : undefined
      }
      filterOption={(value, option) =>
        option?.label?.toString().toLowerCase().includes(value.toLowerCase()) ||
        option?.value?.toString().toLowerCase().includes(value.toLowerCase()) ||
        false
      }
      options={(additionalOptions || []).concat(
        persistedData.map(paramsMethod)
      )}
      dropdownRender={(menu) => {
        if (isFetching)
          return (
            <Spin size="small" tip="Loading">
              {menu}
            </Spin>
          );
        if (error) return <div>Error occurred while loading data</div>;
        return menu;
      }}
      {...selectProps}
    />
  );
};
