import React from "react";
import { Form, Modal } from "antd";
import { useFetcher, useNavigate, useParams } from "react-router-dom";
import { SearchInput } from "components/form/SearchInput";
import { ResponseAlert } from "components/form/ResponseAlert";
import { makeAction, useLoaderData } from "react-router-typesafe";
import {
  ManageTherapyGroup,
  groupEducationAssignmentsQuery,
  therapyGroupQuery,
} from "../ManageTherapyGroup";
import { handleErrorResponse } from "utils/loaderHelpers";
import { toast } from "react-toastify";
import { queryClient } from "api/api_client";
import { useQuery } from "@tanstack/react-query";
import { educationAssignmentsApi } from "api/resources/educationAssignments";
import { educationsApi } from "api/resources/educations";

export namespace NewEducationAssignment {
  export const action = makeAction(async ({ request, params }) =>
    educationAssignmentsApi.create
      .call(await request.json())
      .then(() => {
        toast.success("Education assigned");
        queryClient.invalidateQueries({
          queryKey: groupEducationAssignmentsQuery(params).queryKey,
          exact: true,
        });
        return null;
      })
      .catch(handleErrorResponse)
  );

  export const Element = () => {
    const [form] = Form.useForm();
    const params = useParams();
    const navigate = useNavigate();
    const initialData = useLoaderData<typeof ManageTherapyGroup.loader>();
    const {
      data: { data: therapyGroup },
    } = useQuery({ ...therapyGroupQuery(params), initialData });
    const submitFetcher = useFetcher();

    const formItems = [
      <Form.Item
        label="Education"
        name="education_id"
        key={1}
        rules={[{ required: true }]}
      >
        <SearchInput
          queryOptionsFn={(title) =>
            educationsApi.getList.query({ filters: { title } })
          }
          paramsMethod={(e) => ({ label: e.title, value: e.id })}
        />
      </Form.Item>,
    ];

    const onFinish = (values: any) => {
      submitFetcher.submit(
        {
          ...values,
          education_assignments_users_attributes: therapyGroup.members.map(
            (m) => ({ user_id: m.user_id })
          ),
          therapy_group_id: therapyGroup.id,
        },
        {
          method: "POST",
          encType: "application/json",
        }
      );
    };

    return (
      <Modal
        open
        title="Assign Education"
        onOk={() => form.submit()}
        onCancel={() => navigate(-1)}
      >
        <Form
          name="educationAssignmentsCreate"
          style={{ marginTop: "20px" }}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark="optional"
        >
          {formItems}

          {submitFetcher.data && (
            <ResponseAlert response={submitFetcher.data} />
          )}
        </Form>
      </Modal>
    );
  };

  export const element = <Element />;
}
