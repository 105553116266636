import React from "react";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { therapyGroupsApi } from "api/resources/therapyGroups";
import { makeLoader, useLoaderData } from "react-router-typesafe";
import { PageTitle } from "components/PageTitle";
import { Link, Outlet } from "react-router-dom";
import { therapyGroupPath } from "utils/paths";
import FormattedDate from "components/FormattedDate";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "api/api_client";

const columns: ColumnsType<TherapyGroup> = [
  {
    title: "Level of Care",
    dataIndex: "level_of_care",
    key: "level_of_care",
  },
  {
    title: "Type",
    dataIndex: "group_type",
    key: "group_type",
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    render: (value) => value.name,
  },
  {
    title: "Number of attendees",
    dataIndex: "member_count",
    key: "member_count",
  },
  {
    title: "Facilitator",
    dataIndex: "facilitator",
    key: "facilitator",
    render: (value) => value.name,
  },
  {
    title: "Created at",
    key: "created_at",
    dataIndex: "created_at",
    render: (value) => <FormattedDate value={value} />,
  },
  {
    title: "Actions",
    key: "actions",
    render: (_, record) => (
      <Link to={therapyGroupPath(record.id)}>Manage Group</Link>
    ),
  },
];

export namespace TherapyGroups {
  export const loader = makeLoader(() =>
    queryClient.ensureQueryData(therapyGroupsApi.getList.query())
  );

  export const Element = () => {
    const initialData = useLoaderData<typeof loader>();
    const {
      data: { data: therapyGroups },
    } = useQuery({
      ...therapyGroupsApi.getList.query(),
      initialData,
      staleTime: 2 * 60 * 1000,
    });

    return (
      <>
        <PageTitle title="Therapy Groups">
          <Link to="new">
            <Button type="primary">Create Group</Button>
          </Link>
        </PageTitle>
        <Table
          columns={columns}
          dataSource={therapyGroups}
          size="small"
          pagination={{
            size: "default",
          }}
          rowKey="id"
        />

        <Outlet />
      </>
    );
  };

  export const element = <Element />;
}

export default TherapyGroups;
