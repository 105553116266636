import { makeApiResource } from "api/makeApiResource";
import { apiFetcher } from "../api_client";

const basePath = "/admin/therapy_groups";

export const therapyGroupsApi = makeApiResource({
  baseQueryKey: ["therapy_groups"],
  getList: (queryParams?: any, _context?: any) =>
    apiFetcher<ApiDataResponse<TherapyGroup[]>>(basePath, { queryParams }),
  get: (id: string, _context?: any) =>
    apiFetcher<ApiDataResponse<TherapyGroup>>(`${basePath}/${id}`),
  create: (body: any, _context?: any) =>
    apiFetcher<ApiDataResponse<TherapyGroup>>(basePath, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }),
  update: (id: string, body: any, _context?: any) =>
    apiFetcher<ApiDataResponse<TherapyGroup>>(`${basePath}/${id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }),
});
