import React from "react";
import { groupAppointmentPath } from "utils/paths";
import { Link, useFetcher, useParams } from "react-router-dom";
import Descriptions, { DescriptionsItemType } from "antd/es/descriptions";
import { PageTitle } from "components/PageTitle";
import { makeAction, makeLoader, useLoaderData } from "react-router-typesafe";
import { toast } from "react-toastify";
import { handleErrorResponse } from "utils/loaderHelpers";
import { queryClient } from "api/api_client";
import { useQuery } from "@tanstack/react-query";
import { therapyGroupAppointmentApi } from "api/resources/therapyGroupAppointments";
import { Button, Modal, Space } from "antd";
import { educationAssignmentsApi } from "api/resources/educationAssignments";
import FormattedDate from "components/FormattedDate";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import { NewEducationAssignmentForm } from "./NewEducationAssignmentForm";
import { NewGroupAppointmentForm } from "./NewGroupAppointmentForm";
import { useOnSubmit } from "hooks/useOnSubmit";
import GroupNoteForm from "./GroupNoteForm";

export const groupAppointmentQuery = (params: any) =>
  therapyGroupAppointmentApi.get.query(params.id!);

export namespace ManageGroupAppointment {
  export const loader = makeLoader(({ params }) => {
    return queryClient.ensureQueryData(groupAppointmentQuery(params));
  });

  export const action = makeAction(async ({ request, params }) => {
    const { formId, ...payload } = await request.json();
    switch (formId) {
      case "educationAssignmentsCreate":
        return educationAssignmentsApi.create
          .call(payload)
          .then(() => {
            toast.success("Education assigned");
            queryClient.invalidateQueries({
              queryKey: educationAssignmentsApi.baseQueryKey,
            });
            return null;
          })
          .catch(handleErrorResponse);
      case "groupNoteCreate":
        return therapyGroupAppointmentApi.createNote
          .call(params.id!, payload)
          .then(() => {
            toast.success("Group note created");
            return null;
          })
          .catch(handleErrorResponse);
    }
  });

  export const handle = {
    crumb: ({ data }: ApiDataResponse<TherapyGroupAppointment>) => (
      <Link to={groupAppointmentPath(data.id)}>{data.id}</Link>
    ),
  };

  export const Element = () => {
    const initialData = useLoaderData<typeof loader>();
    const params = useParams();
    const {
      data: { data: groupAppointment },
    } = useQuery({
      ...groupAppointmentQuery(params),
      initialData,
      staleTime: 2 * 60 * 1000,
    });

    const {
      data: educationAssignmentsData,
      isFetching: educationAssignmentsFetching,
    } = useQuery({
      ...educationAssignmentsApi.getList.query({
        filters: { therapy_group_id: groupAppointment.group_id },
      }),
      placeholderData: { data: [] },
      staleTime: 2 * 60 * 1000,
    });

    const submitFetcher = useFetcher();

    const [assignEducationModalOpen, setAssignEducationModalOpen] =
      React.useState(false);
    const [followUpAppointmentModalOpen, setFollowUpAppointmentModalOpen] =
      React.useState(false);
    const [openGroupNoteModal, setOpenGroupNoteModal] = React.useState(false);

    useOnSubmit(submitFetcher, () => {
      setOpenGroupNoteModal(false);
      setFollowUpAppointmentModalOpen(false);
      setAssignEducationModalOpen(false);
    });

    const descriptionItems: DescriptionsItemType[] = [
      {
        key: "date",
        label: "Date",
        children: <FormattedDate value={groupAppointment.start_date} />,
      },
      {
        key: "start_time",
        label: "Start Time",
        children: (
          <FormattedDate value={groupAppointment.start_date} format="time" />
        ),
      },
      {
        key: "end_time",
        label: "End Time",
        children: (
          <FormattedDate value={groupAppointment.end_date} format="time" />
        ),
      },
      {
        key: "practitioner",
        label: "Practitioner",
        children: groupAppointment.practitioner?.name,
      },
      {
        key: "Group Type",
        label: "Group Type",
        children: groupAppointment.group.group_type,
      },
      {
        key: "Type",
        label: "Type",
        children: groupAppointment.appointment_type,
      },
      {
        key: "level_of_care",
        label: "Level of Care",
        children: groupAppointment.group.level_of_care,
      },
      {
        key: "Description",
        label: "Topic",
        children: groupAppointment.description,
      },
    ];

    return (
      <>
        <PageTitle title="Manage Group Appointment">
          <Button type="primary" onClick={() => setOpenGroupNoteModal(true)}>
            Write Group Note
          </Button>
          <Button
            type="primary"
            onClick={() => setFollowUpAppointmentModalOpen(true)}
          >
            Create Follow-up Appointment
          </Button>
        </PageTitle>
        <Descriptions items={descriptionItems} />

        <Space direction="vertical" style={{ width: "100%" }}>
          <Title level={4}>Participants</Title>
          <Table
            columns={participantColumns}
            dataSource={groupAppointment.participants}
            rowKey={"id"}
            pagination={false}
            size="small"
          />

          <Title level={4}>
            Education
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={() => setAssignEducationModalOpen(true)}
            >
              Assign Education
            </Button>
          </Title>
          <Table
            columns={educationAssignmentColumns}
            dataSource={educationAssignmentsData?.data}
            loading={educationAssignmentsFetching}
            rowKey={"id"}
            pagination={false}
            size="small"
          />
        </Space>

        <Modal
          title="Assign Education"
          open={assignEducationModalOpen}
          destroyOnClose
          onCancel={() => setAssignEducationModalOpen(false)}
          okButtonProps={{
            loading: submitFetcher.state == "submitting",
            htmlType: "submit",
            form: "educationAssignmentsCreate",
          }}
          cancelButtonProps={{
            disabled: submitFetcher.state == "submitting",
          }}
        >
          <NewEducationAssignmentForm
            formId="educationAssignmentsCreate"
            submitFetcher={submitFetcher}
            group={groupAppointment.group}
          />
        </Modal>

        <Modal
          title="Create Follow-up Appointment"
          open={followUpAppointmentModalOpen}
          onCancel={() => setFollowUpAppointmentModalOpen(false)}
          width={650}
          okButtonProps={{
            loading: submitFetcher.state == "submitting",
            htmlType: "submit",
            form: "followUpAppointmentCreate",
          }}
          cancelButtonProps={{
            disabled: submitFetcher.state == "submitting",
          }}
        >
          <NewGroupAppointmentForm
            formId="followUpAppointmentCreate"
            submitFetcher={submitFetcher}
            initialValues={{
              group: groupAppointment.group,
              participants: groupAppointment.participants?.map((p) => p.id),
            }}
            initialUsersSearchData={{ data: groupAppointment.participants }}
          />
        </Modal>

        <Modal
          title="Write Group Note"
          open={openGroupNoteModal}
          onCancel={() => setOpenGroupNoteModal(false)}
          width={1050}
          okButtonProps={{
            loading: submitFetcher.state == "submitting",
            htmlType: "submit",
            form: "groupNoteCreate",
          }}
          cancelButtonProps={{
            disabled: submitFetcher.state == "submitting",
          }}
        >
          <GroupNoteForm
            formId={"groupNoteCreate"}
            appointments={groupAppointment.user_appointments}
            submitFetcher={submitFetcher}
          />
        </Modal>
      </>
    );
  };

  export const element = <Element />;
}

const participantColumns: ColumnsType<User> = [
  {
    title: "First Name",
    key: "first_name",
    render: (_, record) => record.first_name,
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    render: (_, record) => record.last_name,
  },
  {
    title: "DOB",
    dataIndex: "birthdate",
    render: (_, record) => record.birthdate,
  },
];

const educationAssignmentColumns: ColumnsType<EducationAssignment> = [
  {
    title: "Education Title",
    key: "title",
    render: ({ education }) => education.title,
  },
  {
    title: "Assigned at",
    key: "created_at",
    dataIndex: "created_at",
    render: (value) => <FormattedDate value={value} />,
  },
];
