import React from "react";
import { Button, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { makeAction, makeLoader, useLoaderData } from "react-router-typesafe";
import { PageTitle } from "components/PageTitle";
import { Link, redirect, useFetcher, useSearchParams } from "react-router-dom";
import { groupAppointmentPath } from "utils/paths";
import FormattedDate from "components/FormattedDate";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "api/api_client";
import { therapyGroupAppointmentApi } from "api/resources/therapyGroupAppointments";
import { NewGroupAppointmentForm } from "./NewGroupAppointmentForm";
import { toast } from "react-toastify";
import { handleErrorResponse } from "utils/loaderHelpers";
import { useForm } from "antd/es/form/Form";
import { searchParamsToObject } from "utils/urlParams";
import { useOnSubmit } from "hooks/useOnSubmit";

const columns: ColumnsType<TherapyGroupAppointment> = [
  {
    title: "Date",
    dataIndex: "start_date",
    key: "date",
    render: (value) => <FormattedDate value={value} />,
  },
  {
    title: "Start Time",
    dataIndex: "start_date",
    key: "start_time",
    render: (value) => (
      <FormattedDate
        value={value}
        options={{ hour: "numeric", minute: "numeric" }}
      />
    ),
  },
  {
    title: "End Time",
    dataIndex: "end_date",
    key: "end_time",
    render: (value) => (
      <FormattedDate
        value={value}
        options={{ hour: "numeric", minute: "numeric" }}
      />
    ),
  },
  {
    title: "Number of Attendees",
    dataIndex: "participant_count",
    key: "participant_count",
  },
  {
    title: "Level of Care",
    dataIndex: ["group", "level_of_care"],
    key: "level_of_care",
  },
  {
    title: "Group Type",
    dataIndex: ["group", "group_type"],
    key: "group_type",
  },
  {
    title: "Topic",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Type",
    dataIndex: "appointment_type",
    key: "appointment_type",
  },
  {
    title: "Practitioner",
    dataIndex: ["practitioner", "name"],
    key: "practitioner",
  },
  {
    title: "Actions",
    key: "actions",
    render: (_, record) => (
      <Link to={groupAppointmentPath(record.id)}>Manage</Link>
    ),
  },
];

export namespace GroupAppointments {
  export const loader = makeLoader(({ request }) => {
    const searchParams = new URL(request.url).searchParams;
    return queryClient.ensureQueryData(
      therapyGroupAppointmentApi.getList.query(
        searchParamsToObject(searchParams)
      )
    );
  });

  export const action = makeAction(async ({ request }) =>
    therapyGroupAppointmentApi.create
      .call(await request.json())
      .then(() => {
        toast.success("Group Appointment created");
        queryClient.invalidateQueries({
          queryKey: therapyGroupAppointmentApi.baseQueryKey,
        });
        return true;
      })
      .catch(handleErrorResponse)
  );

  export const Element = () => {
    const initialData = useLoaderData<typeof loader>();
    const [createModalOpen, setCreateModalOpen] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const {
      data: { data: groupAppointments, meta },
    } = useQuery({
      ...therapyGroupAppointmentApi.getList.query(
        searchParamsToObject(searchParams)
      ),
      initialData,
      staleTime: 2 * 60 * 1000,
    });

    const submitFetcher = useFetcher();

    useOnSubmit(submitFetcher, () => {
      setCreateModalOpen(false);
    });

    return (
      <>
        <PageTitle title="Group Appointments">
          <Button type="primary" onClick={() => setCreateModalOpen(true)}>
            Create Appointment
          </Button>
        </PageTitle>
        <Table
          columns={columns}
          dataSource={groupAppointments}
          size="middle"
          pagination={{
            size: "default",
            pageSize: meta?.page_size,
            current: meta?.current_page,
            showTotal: (total) => `Total ${total}`,
            showSizeChanger: true,
            total: meta?.total_count,
            onChange(page, pageSize) {
              setSearchParams((prev) => ({
                ...prev,
                page: page.toString(),
                per: pageSize.toString(),
              }));
            },
          }}
          rowKey="id"
        />

        <Modal
          open={createModalOpen}
          title="Create Group Appointment"
          okText="Create"
          onCancel={() => setCreateModalOpen(false)}
          width={650}
          okButtonProps={{
            loading: submitFetcher.state == "submitting",
            htmlType: "submit",
            form: "groupAppointmentCreate",
          }}
          cancelButtonProps={{ disabled: submitFetcher.state == "submitting" }}
          destroyOnClose
        >
          <NewGroupAppointmentForm
            formId="groupAppointmentCreate"
            submitFetcher={submitFetcher}
          />
        </Modal>
      </>
    );
  };

  export const element = <Element />;
}

export default GroupAppointments;
