import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export const BooleanIcon = ({ value }: { value: boolean }) => {
  if (value) {
    return <CheckOutlined style={{ color: "green" }} />;
  } else {
    return <CloseOutlined style={{ color: "red" }} />;
  }
};
