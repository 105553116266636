export const therapyGroupsPath = "/therapy_groups";
export const therapyGroupPath = (id: string) => `${therapyGroupsPath}/${id}`;
export const therapyGroupNewPath = `${therapyGroupsPath}/new`;

export const groupAppointmentsPath = "/group_appointments";
export const groupAppointmentPath = (id: string) =>
  `${groupAppointmentsPath}/${id}`;

export const appointmentsPath = "/appointments";
export const notificationEventsPath = "/notification_events";

export const joinAppointmentPagePath =
  process.env.REACT_APP_BASE_API_URL + "/join_appointment";

export const usersPath = "/users";
export const userPath = (id: string) => `${usersPath}/${id}`;

export const calendarSyncInfosPath = "/calendar_sync_infos";
export const calendarSyncInfoPath = (id: string) =>
  `${calendarSyncInfosPath}/${id}`;
