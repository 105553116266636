import React from "react";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { PageTitle } from "components/PageTitle";
import { Link } from "react-router-dom";
import FormattedDate from "components/FormattedDate";
import { useQuery } from "@tanstack/react-query";
import { notificationsApi } from "api/resources/notifications";

const columns: ColumnsType<UserNotification> = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Receiver",
    dataIndex: "user",
    key: "user",
    render: (user) => `${user.first_name} ${user.last_name} ${user.email}`,
  },
  {
    title: "Channels",
    dataIndex: "attempted_channels",
    key: "attempted_channels",
  },
];

type UserNotificationsProps = {
  notification_event_id?: string;
};

export const UserNotifications = ({
  notification_event_id,
}: UserNotificationsProps) => {
  const { data } = useQuery({
    ...notificationsApi.getList.query({ filters: { notification_event_id } }),
    staleTime: 2 * 60 * 1000,
  });

  if (!data) return <></>;

  return (
    <Table
      columns={columns}
      dataSource={data.data}
      size="large"
      pagination={{
        size: "default",
      }}
      rowKey="id"
    />
  );
};
