import React from "react";
import { Form, Input, Select } from "antd";
import { Fetcher, FetcherWithComponents, useFetcher } from "react-router-dom";
import { ResponseAlert } from "components/form/ResponseAlert";
import { useQuery } from "@tanstack/react-query";
import { useForm, useWatch } from "antd/es/form/Form";
import { googleCalendarsApi } from "api/resources/google_calendars";
import useDebounce from "hooks/useDebounce";
import { useOnChange } from "hooks/useOnChange";

type CalendarFormProps = {
  // form: FormInstance;
  submitFetcher: FetcherWithComponents<any>;
  formId?: string;
};

export const CalendarForm = ({
  formId = "calendarCreate",
  submitFetcher,
}: CalendarFormProps) => {
  const [form] = useForm();
  const accountIdWatch = useWatch("account_id", form);
  const accountId = useDebounce(accountIdWatch, 1000);
  const {
    data: calendarsData,
    error,
    isFetching,
  } = useQuery({
    ...googleCalendarsApi.getAccountCalendars.query({
      account_id: accountId,
    }),
    enabled: !!accountId,
    retry: false,
  });

  useOnChange(isFetching, () => form.validateFields(["account_id"]));

  const onFinish = (values: any) => {
    submitFetcher.submit(
      {
        formId: formId,
        ...values,
      },
      {
        method: "POST",
        encType: "application/json",
      }
    );
  };

  return (
    <Form
      name={formId}
      id={formId}
      style={{ marginTop: "20px" }}
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        label="Account ID (email)"
        name="account_id"
        hasFeedback
        validateStatus={(isFetching && "validating") || (error && "error")}
        tooltip="The email address of the Google account which has access to the calendar you want to sync with."
        rules={[
          { required: true },
          {
            validator: (_, value) => {
              return error
                ? Promise.reject(error.errors[0])
                : Promise.resolve();
            },
            message: error?.errors?.at(0),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Calendar"
        name="calendar_id"
        rules={[{ required: true }]}
      >
        <Select
          disabled={!accountId || isFetching || !!error}
          options={calendarsData?.data?.map((calendar: any) => ({
            label: calendar.summary,
            value: calendar.id,
          }))}
          onChange={(_, option: any) => {
            form.setFieldsValue({ name: `${option.label} (${accountId})` });
          }}
        />
      </Form.Item>

      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>

      {submitFetcher.data?.errors && (
        <ResponseAlert response={submitFetcher.data} />
      )}
    </Form>
  );
};
