import { Breadcrumb } from "antd";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import * as React from "react";
import { useMatches } from "react-router";

interface RouteHandleType {
  crumb?: (data?: any) => React.ReactNode;
}

export const Breadcrumbs = () => {
  const matches = useMatches();
  const crumbs: BreadcrumbItemType[] = matches
    .filter((match) =>
      Boolean(match.handle && (match.handle as RouteHandleType).crumb)
    )
    .map((match, index) => ({
      title: (match.handle as RouteHandleType).crumb!(match.data),
      key: index,
    }));

  return <Breadcrumb style={{ margin: "16px 0" }} items={crumbs} />;
};
