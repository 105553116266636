import React, { useEffect, useRef, useState } from "react";

const useDebounce = <T>(
  value: T,
  delay: number,
  callback?: (value: T) => void
) => {
  const [debouncedValue, setDebouncedValue] = useState<T | undefined>(
    undefined
  );
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (callback) callback(value);
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timerRef.current);
  }, [value, delay]);

  return debouncedValue;
};

// export const useDebounce = <T>(
//   defaultValue: T,
//   delay: number,
//   callback?: (value: T) => void
// ) => {
//   const [value, setValue] = useState(defaultValue);
//   const [debouncedValue, setDebouncedValue] = useState(value);

//   useEffect(() => {
//     const timeoutId = setTimeout(() => {
//       if (callback) callback(value);
//       setDebouncedValue(value);
//     }, delay);

//     return () => clearTimeout(timeoutId);
//   }, [value]);

//   return [debouncedValue, setValue] as [
//     T,
//     React.Dispatch<React.SetStateAction<T>>
//   ];
// };

export default useDebounce;
