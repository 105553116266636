import React from "react";
import { Alert, AlertProps } from "antd";

type ResponseAlertProps = AlertProps & {
  response: ApiErrorsResponse;
};

export const ResponseAlert = ({
  response,
  ...alertProps
}: ResponseAlertProps) => {
  console.log(response);
  const description = constructErrorsMessages(response.errors).join("\n");
  return (
    <Alert
      message="Invalid Data"
      description={description}
      type="error"
      showIcon
      closable
      {...alertProps}
    />
  );
};

const constructErrorsMessages = (
  errors: { [key: string]: string[] } | string[]
) => {
  if (Array.isArray(errors)) return errors;

  return Object.entries(errors).map(
    ([field, errors]) => `${field} ${errors.join(", ")}`
  );
};
