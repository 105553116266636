import React from "react";
import { Form, Input, Radio, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { FetcherWithComponents } from "react-router-dom";

const columns: ColumnsType<Appointment> = [
  {
    title: "First name",
    dataIndex: ["user", "first_name"],
    key: "first_name",
  },
  {
    title: "Last name",
    dataIndex: ["user", "last_name"],
    key: "last_name",
  },
  {
    title: "Date of Birth",
    dataIndex: ["user", "birthdate"],
    key: "birthdate",
  },
  {
    title: "Attendance",
    key: "attendance",
    render: (_, record) => (
      <Form.Item
        name={["appointments", record.canvas_id, "status"]}
        style={{ marginBottom: 0 }}
        rules={[{ required: true, message: "Please select an option" }]}
      >
        <Radio.Group
          onChange={(e) => {
            console.log(e.target.value);
          }}
        >
          <Row>
            <Radio value="arrived">Attended</Radio>
          </Row>
          <Row>
            <Radio value="noshow">No show</Radio>
          </Row>
        </Radio.Group>
      </Form.Item>
    ),
  },
  {
    title: "Invididual Engagement",
    key: "individual_engagement",
    render: (_, record) => (
      <Form.Item
        name={["appointments", record.canvas_id, "individual_engagement"]}
        style={{ marginBottom: 0 }}
      >
        <Input.TextArea size="small" />
      </Form.Item>
    ),
  },
  {
    title: "Clinical Impression",
    key: "clinical_impression",
    render: (_, record) => (
      <Form.Item
        name={["appointments", record.canvas_id, "clinical_impression"]}
        style={{ marginBottom: 0 }}
      >
        <Input.TextArea size="small" />
      </Form.Item>
    ),
  },
];

type GroupNoteFormProps = {
  formId?: string;
  appointments: Appointment[] | undefined;
  submitFetcher: FetcherWithComponents<any>;
};

const GroupNoteForm = ({
  formId = "groupNoteCreate",
  appointments,
  submitFetcher,
}: GroupNoteFormProps) => {
  const onFinish = (values: any) => {
    const { appointments, ...rest } = values;
    const modifiedAppointments = Object.entries(appointments).map(
      ([canvas_id, values]) => {
        return {
          ...(values as any),
          canvas_id,
        };
      }
    );

    submitFetcher.submit(
      {
        formId,
        therapy_group_note: { ...rest, appointments: modifiedAppointments },
      },
      {
        method: "POST",
        encType: "application/json",
      }
    );
  };

  return (
    <Form
      name={formId}
      style={{ marginTop: "20px" }}
      labelAlign="left"
      onFinish={onFinish}
      colon={false}
      labelCol={{ span: 4 }}
      layout="vertical"
    >
      <Form.Item
        name="intervention"
        label="Intervention"
        rules={[{ required: true }]}
      >
        <Input.TextArea size="large" />
      </Form.Item>

      <Form.Item
        name="group_note"
        label="Group Note"
        rules={[{ required: true }]}
      >
        <Input.TextArea size="large" />
      </Form.Item>

      <Typography.Text strong>Participants</Typography.Text>
      <Table
        dataSource={appointments}
        pagination={false}
        columns={columns}
        rowKey={(record) => record.id}
      />
    </Form>
  );
};

export default GroupNoteForm;
