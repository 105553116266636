import {
  $sessionStartDate,
  setAuthorizationToken,
  setSessionStartDate,
} from "api/api_client/store";
import { sessionsApi } from "api/resources/sessions";
import { useEffect } from "react";

export const useRefreshSession = () => {
  return useEffect(() => {
    const sessionStart = $sessionStartDate.get();
    const delay = sessionStart
      ? Math.max(1000 * 60 * 60 * 4 - (Date.now() - sessionStart.getTime()), 0)
      : 0;

    setTimeout(() => {
      refreshSession();
      setInterval(refreshSession, 1000 * 60 * 60 * 4);
    }, delay);
  }, []);
};

const refreshSession = () =>
  sessionsApi.create.call().then(({ data }) => {
    setAuthorizationToken(data.token);
    setSessionStartDate(new Date());
  });
