import { objectToUrlParams } from "utils/urlParams";
import { $requestHeaders } from "./store";
import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({});

export type ExtendedRequestOpts = {
  queryParams?: any;
} & RequestInit;

export const apiFetcher = <RT>(
  path: RequestInfo,
  options: ExtendedRequestOpts = {}
) => {
  const { headers: optionHeaders, ...otherOptions } = options;
  const urlParams = options.queryParams
    ? `?${objectToUrlParams(options.queryParams)}`
    : "";

  const combinedHeaders = {
    ...$requestHeaders.get(),
    ...optionHeaders,
  };

  return fetch(`${process.env.REACT_APP_BASE_API_URL}${path}${urlParams}`, {
    headers: combinedHeaders,
    ...otherOptions,
  }).then(async (response) => {
    console.log(response);
    if (response.ok) return (await tryJson(response)) as RT;
    if (response.status === 500) throw { status: 500, message: "Server error" };
    throw {
      status: response.status,
      errors: (await tryJson(response)).errors,
    };
  });
};

const tryJson = async (response: Response) => {
  try {
    return await response.json();
  } catch (e) {
    return null;
  }
};
