import React from "react";
import { Button, Flex, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import FormattedDate from "components/FormattedDate";
import { useQuery } from "@tanstack/react-query";
import { groupAppointmentsQuery } from "../ManageTherapyGroup";
import { Link } from "react-router-dom";
import { substractDates } from "utils/helpers";

type TherapyGroupAppointmentsListProps = {
  therapyGroup: TherapyGroup;
};

const columns: ColumnsType<TherapyGroupAppointment> = [
  {
    title: "Start date",
    dataIndex: "start_date",
    key: "start_date",
    render: (value) => <FormattedDate value={value} />,
  },
  {
    title: "Duration",
    key: "duration",
    render: (_, record) =>
      `${
        substractDates(record.end_date, record.start_date) / (1000 * 60)
      } minutes`,
  },
  {
    title: "Practitioner",
    dataIndex: "practitioner",
    key: "practitioner",
    render: (value) => value.name,
  },
  {
    title: "Type",
    dataIndex: "appointment_type",
    key: "appointment_type",
  },
  {
    title: "Participants",
    dataIndex: "participants",
    key: "participants",
    render: (participants: User[]) =>
      participants.map((p, i) => <div key={i}>{p.email}</div>),
  },
];

export const TherapyGroupAppointments = ({
  therapyGroup,
}: TherapyGroupAppointmentsListProps) => {
  const {
    data: { data: groupAppointments },
    isFetching,
  } = useQuery({
    ...groupAppointmentsQuery(therapyGroup),
    staleTime: 2 * 60 * 1000,
  });

  return (
    <Flex vertical align="flex-end" gap="10px" style={{ width: "100%" }}>
      <Link to="appointments/new">
        <Button type="primary">Create appointment</Button>
      </Link>

      <Table
        columns={columns}
        dataSource={groupAppointments}
        rowKey={({ id }) => id}
        loading={isFetching}
        style={{ width: "100%" }}
        pagination={false}
        size="small"
        virtual
      />
    </Flex>
  );
};
