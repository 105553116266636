import { apiFetcher } from "../api_client";
import { makeApiResource } from "api/makeApiResource";

const basePath = "/admin/practitioners";

export const practitionersApi = makeApiResource({
  baseQueryKey: ["practitioners"],
  getList: (queryParams?: any, _context?: any) =>
    apiFetcher<ApiDataResponse<Practitioner[]>>(basePath, { queryParams }),
  get: (id: string, _context?: any) =>
    apiFetcher<ApiDataResponse<Practitioner>>(`${basePath}/${id}`),
});
