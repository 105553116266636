import { FetcherWithComponents } from "react-router-dom";
import { useOnChange } from "./useOnChange";

export const useOnSubmit = (
  submitFetcher: FetcherWithComponents<any>,
  callback: (fetcher: FetcherWithComponents<any>) => void
) => {
  return useOnChange(submitFetcher.state, (prev, cur) => {
    console.log(prev, cur, submitFetcher.data);
    if (
      cur === "idle" &&
      submitFetcher.data !== false &&
      !submitFetcher.data?.errors
    ) {
      callback(submitFetcher);
    }
  });
};
