import { apiFetcher } from "../api_client";
import { makeApiResource } from "api/makeApiResource";

const basePath = "/admin/action_tokens";

export const actionTokensApi = makeApiResource({
  baseQueryKey: ["action_tokens"],
  getList: (queryParams?: any, _context?: any) =>
    apiFetcher<ApiDataResponse<ActionToken[]>>(basePath, { queryParams }),
});
