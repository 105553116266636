import { apiFetcher } from "../api_client";
import { makeApiResource } from "api/makeApiResource";

const basePath = "/admin/appointments";

export const appointmentsApi = makeApiResource({
  baseQueryKey: ["appointments"],
  getList: (queryParams?: any, _context?: any) =>
    apiFetcher<ApiDataResponse<Appointment[]>>(basePath, { queryParams }),
  getJoinCode: (id: string, _context?: any) =>
    apiFetcher<ApiDataResponse<Appointment>>(`${basePath}/${id}/join_code`),
  getJoinLink: (id: string, _context?: any) =>
    apiFetcher<ApiDataResponse<{ join_link: string }>>(
      `${basePath}/${id}/join_link`
    ),
});
