import { UseQueryOptions, queryOptions, useQuery } from "@tanstack/react-query";

type ApiActionType = "getList" | "get" | "create" | "update";

type ApiFunction<FnArgs extends any[], FnReturnType> = (
  ...args: FnArgs
) => Promise<FnReturnType>;

type ExtractPromise<T> = T extends Promise<infer X> ? X : T;

type ResourceAction<FnArgs extends any[], FnReturnType> = {
  call: ApiFunction<FnArgs, FnReturnType>;
  query: (...args: FnArgs) => UseQueryOptions<FnReturnType, any, FnReturnType>;
};

type HasBaseQueryKey = {
  baseQueryKey: readonly any[];
};

type RemoveBaseQueryKey<T> = {
  [K in keyof T as Exclude<K, "baseQueryKey"> & string]: T[K];
};

type ApiResource<T> = {
  [K in keyof RemoveBaseQueryKey<T>]: T[K] extends (...args: any) => any
    ? ResourceAction<Parameters<T[K]>, ExtractPromise<ReturnType<T[K]>>>
    : never;
} & HasBaseQueryKey;

export const makeApiResourcePrev = <T extends HasBaseQueryKey>(
  resource: T,
  actionTypes: ApiActionType[] = ["getList", "get", "create", "update"]
): ApiResource<T> => {
  const result = resource as any;
  for (const actionType of actionTypes) {
    const actionFn = resource[actionType as keyof T & string];
    if (typeof actionFn !== "function") continue;

    result[actionType] = restAction(
      actionType,
      resource.baseQueryKey,
      actionFn as T[keyof T & string] extends (...args: any) => any
        ? ApiFunction<
            Parameters<T[keyof T & string]>,
            ExtractPromise<ReturnType<T[keyof T & string]>>
          >
        : never
    );
  }
  return result;
};

export const makeApiResource = <T extends HasBaseQueryKey>(
  resource: T
): ApiResource<T> => {
  const result = resource as any;
  const { baseQueryKey, ...resourceActions } = resource;

  for (const actionType of Object.keys(resourceActions)) {
    const actionFn = resource[actionType as keyof T & string];
    if (typeof actionFn !== "function") continue;

    result[actionType] = restAction(
      actionType as ApiActionType,
      baseQueryKey,
      actionFn as T[keyof T & string] extends (...args: any) => any
        ? ApiFunction<
            Parameters<T[keyof T & string]>,
            ExtractPromise<ReturnType<T[keyof T & string]>>
          >
        : never
    );
  }

  return result;
};

const constructQueryKey = (
  actionType: ApiActionType,
  baseQueryKey: readonly any[],
  args: any[]
): readonly any[] => {
  switch (actionType) {
    case "getList":
      if (args[0]) return [...baseQueryKey, "list", args[0]]; // queryParams
      return [...baseQueryKey, "list"];
    case "get":
      return [...baseQueryKey, args[0]]; // id
    case "create":
      return [...baseQueryKey];
    case "update":
      return [...baseQueryKey, args[0]]; // id
    default:
      return [...baseQueryKey, actionType, args];
  }
};

function restAction<FetchFnArgs extends any[], FetchFnReturnType>(
  actionType: ApiActionType,
  baseQueryKey: readonly any[],
  fetchFunction: ApiFunction<FetchFnArgs, FetchFnReturnType>
): ResourceAction<FetchFnArgs, FetchFnReturnType> {
  return {
    call: fetchFunction,
    query: (...args: FetchFnArgs) =>
      queryOptions({
        queryKey: constructQueryKey(actionType, baseQueryKey, args),
        queryFn: (context) => {
          for (let i = args.length; i < fetchFunction.length - 1; i++)
            args.push(undefined);
          args[fetchFunction.length - 1] = context;
          return fetchFunction(...args);
        },
      }),
  };
}
