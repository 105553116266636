import { makeApiResource } from "api/makeApiResource";
import { apiFetcher } from "../api_client";

const basePath = "/admin/educations";

export const educationsApi = makeApiResource({
  baseQueryKey: ["educations"],
  getList: (queryParams: any, _context?: any) =>
    apiFetcher<ApiDataResponse<Education[]>>(basePath, {
      queryParams,
    }),
  create: (payload: any, _context?: any) =>
    apiFetcher<ApiDataResponse<Education>>(basePath, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }),
});
