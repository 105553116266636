export const objectToUrlParams = (value: any, path?: string): any => {
  if (value === undefined) return "";
  if (value === null) return path + "=null";
  if (typeof value === "object") {
    if (Array.isArray(value))
      return value.map((x) => objectToUrlParams(x, path + "[]")).join("&");
    return Object.entries(value)
      .map(([key, x]) => {
        const newPath = path ? `${path}[${key}]` : key;
        return objectToUrlParams(x, newPath);
      })
      .join("&");
  }
  return path + "=" + encodeURIComponent(value);
};

// TODO: Add conversion on nested keys to objects. Ex. foo[bar]=baz
export function searchParamsToObject(searchParams: URLSearchParams): any {
  const result = {} as any;
  for (const [key, value] of searchParams.entries()) {
    result[key] = value;
  }
  return result;
}
