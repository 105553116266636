import React, { useEffect } from "react";
import { userPath } from "utils/paths";
import { Link, useFetcher, useParams } from "react-router-dom";
import Descriptions, { DescriptionsItemType } from "antd/es/descriptions";
import { PageTitle } from "components/PageTitle";
import { makeAction, makeLoader, useLoaderData } from "react-router-typesafe";
import { queryClient } from "api/api_client";
import { useQuery } from "@tanstack/react-query";
import { Button, Collapse, Popconfirm, Space, Table } from "antd";
import { usersApi } from "api/resources/users";
import { BooleanIcon } from "components/BooleanIcon";
import Title from "antd/es/typography/Title";
import { ColumnsType } from "antd/es/table";
import FormattedDate from "components/FormattedDate";
import { actionTokensApi } from "api/resources/action_tokens";
import { toast } from "react-toastify";
import { levelOfCarePeriodsApi } from "api/resources/level_of_care_periods";

const userQuery = (params: any) => usersApi.get.query(params.id!);

export namespace ManageUser {
  export const loader = makeLoader(({ params }) => {
    return queryClient.ensureQueryData(userQuery(params));
  });

  export const action = makeAction(async ({ request, params }) => {
    // console.log(Array.from((await request.formData()).entries()));
    // return null;
    const { formId, ...payload } = await request.json();
    switch (formId) {
      case "sendPasswordResetEmail":
        const user_id = params.id!;
        return usersApi.sendPasswordResetEmail
          .call(user_id)
          .then(() => {
            toast.success("Email has been sent successfully");
            queryClient.invalidateQueries(
              actionTokensApi.getList.query({ filters: { user_id } })
            );
            return null;
          })
          .catch((error) => {
            toast.error("Failed to send password reset email");
          });
    }
  });

  export const handle = {
    crumb: ({ data }: ApiDataResponse<User>) => (
      <Link to={userPath(data.id)}>
        {data.first_name} {data.last_name}
      </Link>
    ),
  };

  export const Element = () => {
    const initialData = useLoaderData<typeof loader>();
    const params = useParams();
    const {
      data: { data: user },
    } = useQuery({
      ...userQuery(params),
      initialData,
      staleTime: 2 * 60 * 1000,
    });

    const { data: actionTokens, isFetching: actionTokensFetching } = useQuery({
      ...actionTokensApi.getList.query({
        filters: { user_id: user.id },
      }),
      placeholderData: { data: [] },
      staleTime: 2 * 60 * 1000,
    });

    const [levelOfCarePeriodsMeta, setLevelOfCarePeriodsMeta] = React.useState({
      page: 1,
      per: 10,
    });

    const { data: levelOfCarePeriods, isFetching: levelOfCarePeriodsFetching } =
      useQuery({
        ...levelOfCarePeriodsApi.getList.query({
          filters: { user_id: user.id },
          ...levelOfCarePeriodsMeta,
        }),
        placeholderData: { data: [] },
        staleTime: 2 * 60 * 1000,
      });

    const resetPasswordSubmitFetcher = useFetcher();

    const descriptionItems: DescriptionsItemType[] = [
      { key: "id", label: "ID", children: user.id },
      { key: "email", label: "Email", children: user.email },
      { key: "first_name", label: "First name", children: user.first_name },
      { key: "last_name", label: "Last name", children: user.last_name },
      { key: "mrn", label: "MRN", children: user.mrn },
      {
        key: "level_of_care",
        label: "Level of Care",
        children: user.level_of_care,
      },
      {
        key: "onboard",
        label: "Onboard",
        children: <BooleanIcon value={user.onboarded} />,
      },
    ];

    return (
      <>
        <PageTitle title={`${user.first_name} ${user.last_name}`} />
        <Descriptions items={descriptionItems} />

        <Space direction="vertical" style={{ width: "100%" }}>
          <Collapse
            items={[
              {
                key: "level_of_care_periods",
                label: "Level of Care Periods",
                children: (
                  <Table
                    columns={levelOfCarePeriodColumns}
                    dataSource={levelOfCarePeriods?.data}
                    loading={levelOfCarePeriodsFetching}
                    rowKey={"id"}
                    pagination={{
                      current: levelOfCarePeriods?.meta?.current_page,
                      pageSize: levelOfCarePeriods?.meta?.page_size,
                      total: levelOfCarePeriods?.meta?.total_count,
                      size: "default",
                      showTotal: (total) => `Total ${total}`,
                      onChange: (page, pageSize) =>
                        setLevelOfCarePeriodsMeta({ page, per: pageSize }),
                    }}
                    size="small"
                  />
                ),
              },
            ]}
          />

          <Title level={4}>
            Action Tokens
            <Popconfirm
              title="This will send a new email and invalidate previously sent links. Are you sure to send it?"
              onConfirm={() =>
                resetPasswordSubmitFetcher.submit(
                  {
                    formId: "sendPasswordResetEmail",
                  },
                  { method: "POST", encType: "application/json" }
                )
              }
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                style={{ float: "right" }}
                loading={resetPasswordSubmitFetcher.state == "submitting"}
              >
                Send &quot;Password Reset&quot; email
              </Button>
            </Popconfirm>
          </Title>

          <Table
            columns={actionTokenColumns}
            dataSource={actionTokens?.data}
            loading={actionTokensFetching}
            rowKey={"id"}
            pagination={false}
            size="small"
          />
        </Space>
      </>
    );
  };

  export const element = <Element />;
}

const actionTokenColumns: ColumnsType<ActionToken> = [
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Active",
    dataIndex: "active",
    key: "active",
    render: (active, user) => {
      let value = active;
      if (user.valid_until) {
        value = value && new Date(user.valid_until) > new Date();
      }
      return <BooleanIcon value={value} />;
    },
  },
  {
    title: "Valid until",
    dataIndex: "valid_until",
    key: "valid_until",
    render: (validUntil) => <FormattedDate format="long" value={validUntil} />,
  },
  {
    title: "Last used at",
    dataIndex: "last_usage_at",
    key: "last_usage_at",
    render: (lastUsageAt) => (
      <FormattedDate format="long" value={lastUsageAt} />
    ),
  },
];

const levelOfCarePeriodColumns: ColumnsType<LevelOfCarePeriod> = [
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
  },
  {
    title: "Active",
    dataIndex: "active",
    key: "active",
    render: (active) => <BooleanIcon value={active} />,
  },
  {
    title: "End at",
    dataIndex: "end_at",
    key: "end_at",
    render: (endAt) => <FormattedDate format="long" value={endAt} />,
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    key: "created_at",
    render: (createdAt) => <FormattedDate format="long" value={createdAt} />,
  },
];
