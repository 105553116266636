import * as React from "react";
import { Col, Form, Modal, Row, Select } from "antd";
import { therapyGroupNewPath, therapyGroupsPath } from "utils/paths";
import { useForm } from "antd/es/form/Form";
import { Link, redirect, useFetcher, useNavigate } from "react-router-dom";
import { makeAction } from "react-router-typesafe";
import { SearchInput } from "components/form/SearchInput";
import { toast } from "react-toastify";
import { ResponseAlert } from "components/form/ResponseAlert";
import { handleErrorResponse } from "utils/loaderHelpers";
import { queryClient } from "api/api_client";
import { therapyGroupsApi } from "api/resources/therapyGroups";
import { practitionersApi } from "api/resources/practitioners";
import { arrayToChunks } from "utils/helpers";
import { locationsApi } from "api/resources/locations";

export namespace NewTherapyGroup {
  export const action = makeAction(async ({ request }) =>
    therapyGroupsApi.create
      .call(await request.json())
      .then(() => {
        toast.success("Therapy Group created");
        queryClient.invalidateQueries(therapyGroupsApi.getList.query());
        return redirect(therapyGroupsPath);
      })
      .catch(handleErrorResponse)
  );

  export const handle = {
    crumb: () => <Link to={therapyGroupNewPath}>New</Link>,
  };

  export const Element = () => {
    const [form] = useForm();
    const submitFetcher = useFetcher();
    const navigate = useNavigate();
    const onFinish = (values: any) => {
      submitFetcher.submit(values, {
        method: "POST",
        encType: "application/json",
      });
    };

    const formItems = [
      <Form.Item
        label="Level of Care"
        name="level_of_care"
        key={1}
        rules={[{ required: true }]}
      >
        <Select
          options={[
            "Detoxification",
            "High Intensity Outpatient",
            "Intensive Outpatient",
            "Outpatient",
            "Aftercare",
            "Other",
          ].map((value) => ({ label: value, value }))}
        />
      </Form.Item>,
      <Form.Item
        label="Type"
        name="group_type"
        key={2}
        rules={[{ required: true }]}
      >
        <Select
          options={[
            "Detox",
            "Psychoeducational",
            "Therapeutic",
            "Relapse Prevention",
            "Skills",
            "Processing",
            "Family",
            "Peer",
            "Other",
          ].map((value) => ({ label: value, value }))}
        />
      </Form.Item>,

      <Form.Item
        label="Location"
        name="location_id"
        key={3}
        rules={[{ required: true }]}
      >
        <SearchInput
          queryOptionsFn={() => locationsApi.getList.query()}
          paramsMethod={(location) => ({
            label: location.name,
            value: location.id,
          })}
        />
      </Form.Item>,

      <Form.Item
        label="Facilitator"
        name="facilitator_id"
        key={4}
        rules={[{ required: true }]}
      >
        <SearchInput
          queryOptionsFn={(name) =>
            practitionersApi.getList.query({ filters: { name } })
          }
          paramsMethod={(p) => ({ label: p.name, value: p.id })}
        />
      </Form.Item>,
    ];

    return (
      <Modal
        open
        title="Create Therapy Group"
        okText="Create"
        onOk={() => form.submit()}
        onCancel={() => navigate(-1)}
      >
        <Form
          name="therapyGroupCreate"
          style={{ marginTop: "20px" }}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark="optional"
        >
          {arrayToChunks(formItems, 2).map((itemsRow, rowIndex) => (
            <Row gutter={12} key={rowIndex}>
              {itemsRow.map((item, index) => (
                <Col flex={1} key={index}>
                  {item}
                </Col>
              ))}
            </Row>
          ))}
        </Form>
        {submitFetcher.data && <ResponseAlert response={submitFetcher.data} />}
      </Modal>
    );
  };

  export const element = <Element />;
}
