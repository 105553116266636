import { atom, map, action } from "nanostores";

type AccessToken = string;
export const $accessToken = atom<AccessToken | null>(
  localStorage.getItem("apiToken")
);

const initSessionStartDate = () => {
  const value = localStorage.getItem("sessionStartDate");
  if (!value) return null;
  return new Date(Date.parse(value));
};

export const $sessionStartDate = atom<Date | null>(initSessionStartDate());

export const setSessionStartDate = action(
  $sessionStartDate,
  "setSessionStartDate",
  (store, newValue: Date) => {
    store.set(newValue);
    localStorage.setItem("sessionStartDate", newValue.toISOString());
    return store.get();
  }
);

interface RequestHeaders {
  Authorization?: string;
}

const initHeaders = () => {
  let result = {} as RequestHeaders;
  const token = $accessToken.get();
  if (token) result["Authorization"] = `Bearer ${token}`;
  return result;
};
export const $requestHeaders = map<RequestHeaders>(initHeaders());

export const setAuthorizationToken = action(
  $accessToken,
  "setAuthorizationToken",
  (store, newValue: AccessToken) => {
    store.set(newValue);
    $requestHeaders.setKey("Authorization", `Bearer ${newValue}`);
    localStorage.setItem("apiToken", newValue);
    return store.get();
  }
);
