import { apiFetcher } from "../api_client";
import { makeApiResource } from "api/makeApiResource";

const basePath = "/admin/google_calendars";

export const googleCalendarsApi = makeApiResource({
  baseQueryKey: ["google_calendars"],
  getList: (queryParams?: any, _context?: any) =>
    apiFetcher<ApiDataResponse<GoogleCalendar[]>>(basePath, {
      queryParams,
    }),
  create: (data: Partial<GoogleCalendar>, _context?: any) =>
    apiFetcher<ApiDataResponse<GoogleCalendar>>(basePath, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }),
  getAccountCalendars: (queryParams?: any, context?: any) => {
    return apiFetcher<ApiDataResponse<GoogleCalendar[]>>(
      `${basePath}/account_calendars`,
      {
        queryParams,
      }
    );
  },
});
