import { apiFetcher } from "../api_client";
import { makeApiResource } from "api/makeApiResource";

const basePath = "/admin/notifications";

export const notificationsApi = makeApiResource({
  baseQueryKey: ["notifications"],
  getList: (queryParams?: any, _context?: any) =>
    apiFetcher<ApiDataResponse<UserNotification[]>>(basePath, { queryParams }),
});
