import React from "react";
import { Form, Input } from "antd";
import { useFetcher, useNavigate } from "react-router-dom";
import { FormInstance, useForm } from "antd/es/form/Form";
import { ResponseAlert } from "components/form/ResponseAlert";
import { SearchInput } from "components/form/SearchInput";
import { usersApi } from "api/resources/users";

export type NewNotificationEventFormProps = {
  form: FormInstance;
};

export const NewNotificationEventForm = ({
  form,
}: NewNotificationEventFormProps) => {
  const submitFetcher = useFetcher();

  const navigate = useNavigate();
  const onFinish = (values: any) => {
    submitFetcher.submit(values, {
      method: "POST",
      encType: "application/json",
    });
  };

  return (
    <Form
      name="notificationSendMessage"
      style={{ marginTop: "20px" }}
      form={form}
      layout="vertical"
      onFinish={onFinish}
      requiredMark="optional"
    >
      <Form.Item name="title" label="Title">
        <Input />
      </Form.Item>

      <Form.Item name="body" label="Body">
        <Input.TextArea />
      </Form.Item>

      <Form.Item name="user_ids" label="Recipients" required>
        <SearchInput
          queryOptionsFn={(name) => ({
            ...usersApi.getList.query({ filters: { name } }),
            staleTime: 2 * 60 * 1000,
          })}
          paramsMethod={(user) => ({
            label: `${user.first_name} ${user.last_name} ${user.email}`,
            value: user.id,
          })}
          mode="multiple"
        />
      </Form.Item>

      {submitFetcher.data && <ResponseAlert response={submitFetcher.data} />}
    </Form>
  );
};
