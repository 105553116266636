import { apiFetcher } from "../api_client";
import { makeApiResource } from "api/makeApiResource";

const basePath = "/admin/google_calendar_sync_infos";

export const googleCalendarSyncInfosApi = makeApiResource({
  baseQueryKey: ["google_calendar_sync_infos"],
  getList: (queryParams?: any, _context?: any) =>
    apiFetcher<ApiDataResponse<GoogleCalendarSyncInfo[]>>(basePath, {
      queryParams,
    }),
  create: (data: Partial<GoogleCalendarSyncInfo>, _context?: any) =>
    apiFetcher<ApiDataResponse<GoogleCalendarSyncInfo>>(basePath, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }),
});
