import React, { ReactNode } from "react";
import { Col, Row, Space } from "antd";

type PageTitleProps = {
  title: string | any;
  children?: ReactNode;
};

export const PageTitle = ({ title, children }: PageTitleProps) => {
  return (
    <Row style={{ height: "50px", width: "100%", marginBottom: "10px" }}>
      <Col
        flex="1"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ fontSize: "xx-large", color: "#757575" }}>{title}</div>{" "}
      </Col>

      <Space>{children}</Space>
    </Row>
  );
};

export default PageTitle;
