import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { makeLoader, useLoaderData } from "react-router-typesafe";
import { PageTitle } from "components/PageTitle";
import { Link, useSearchParams } from "react-router-dom";
import { groupAppointmentPath, userPath } from "utils/paths";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "api/api_client";
import { searchParamsToObject } from "utils/urlParams";
import { usersApi } from "api/resources/users";
import { BooleanIcon } from "components/BooleanIcon";

const columns: ColumnsType<User> = [
  {
    title: "First name",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: "Last name",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "MRN",
    dataIndex: "mrn",
    key: "mrn",
  },
  {
    title: "Level of Care",
    dataIndex: "level_of_care",
    key: "level_of_care",
  },
  {
    title: "Onboard",
    dataIndex: "onboarded",
    key: "onboarded",
    render: (onboarded) => <BooleanIcon value={onboarded} />,
  },
  {
    title: "Actions",
    key: "actions",
    render: (_, record) => <Link to={userPath(record.id)}>Manage</Link>,
  },
];

export namespace Users {
  export const loader = makeLoader(({ request }) => {
    const searchParams = new URL(request.url).searchParams;
    return queryClient.ensureQueryData(
      usersApi.getList.query(searchParamsToObject(searchParams))
    );
  });

  export const Element = () => {
    const initialData = useLoaderData<typeof loader>();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
      data: { data: users, meta },
    } = useQuery({
      ...usersApi.getList.query(searchParamsToObject(searchParams)),
      initialData,
      staleTime: 2 * 60 * 1000,
    });

    return (
      <>
        <PageTitle title="Users"></PageTitle>
        <Table
          columns={columns}
          dataSource={users}
          size="middle"
          pagination={{
            size: "default",
            pageSize: meta?.page_size,
            current: meta?.current_page,
            showTotal: (total) => `Total ${total}`,
            showSizeChanger: true,
            total: meta?.total_count,
            onChange(page, pageSize) {
              setSearchParams((prev) => ({
                ...prev,
                page: page.toString(),
                per: pageSize.toString(),
              }));
            },
          }}
          rowKey="id"
        />
      </>
    );
  };

  export const element = <Element />;
}

export default Users;
