export const getTodayDate = () => new Date().toISOString();

export const substractDates = (date1: string, date2: string) => {
  return new Date(date1).valueOf() - new Date(date2).valueOf();
};

type PrepareValuesForApiOptionsType<T> = {
  nestedAttributes?: (keyof T)[];
  initialValues?: T;
};

export const prepareValuesForApi = <T>(
  values: T,
  { nestedAttributes, initialValues }: PrepareValuesForApiOptionsType<T> = {}
) => {
  const modifiedParams = { ...values };

  if (!nestedAttributes) return modifiedParams;

  nestedAttributes.forEach((attributeName) => {
    const modifiedValues = initialValues
      ? addDestroyProperty(
          values[attributeName] as any[],
          initialValues[attributeName] as any[]
        )
      : (values[attributeName] as any[]);

    delete modifiedParams[attributeName];
    modifiedParams[`${String(attributeName)}_attributes` as keyof T] =
      modifiedValues as T[keyof T];
  });

  return modifiedParams;
};

export const arrayToChunks = <T>(array: T[], chunkSize: number): T[][] => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }

  return result;
};

const addDestroyProperty = (
  newNestedObjects: any[],
  initialNestedObjects: any[]
) => {
  const result = [...newNestedObjects];
  initialNestedObjects.forEach(({ id }: any) => {
    if (!newNestedObjects.find((v: any) => v.id === id))
      result.push({ id, _destroy: true });
  });
  return result;
};
