import { makeApiResource } from "api/makeApiResource";
import { apiFetcher } from "../api_client";

const basePath = "/admin/therapy_group_appointments";

export const therapyGroupAppointmentApi = makeApiResource({
  baseQueryKey: ["therapy_group_appointments"],
  getList: (queryParams?: any, _context?: any) =>
    apiFetcher<ApiDataResponse<TherapyGroupAppointment[]>>(basePath, {
      queryParams,
    }),
  get: (id: string, _context?: any) =>
    apiFetcher<ApiDataResponse<TherapyGroupAppointment>>(`${basePath}/${id}`),
  create: (payload: any, _context?: any) =>
    apiFetcher<ApiDataResponse<TherapyGroupAppointment>>(basePath, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }),
  createNote: (threapuGroupId: string, payload: any, _context?: any) =>
    apiFetcher<ApiDataResponse<null>>(
      `${basePath}/${threapuGroupId}/create_note`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    ),
});
