import React, { useMemo } from "react";
import { Button, Descriptions, Input, Table, Form, DatePicker } from "antd";
import { ColumnsType } from "antd/es/table";
import { makeLoader, useLoaderData } from "react-router-typesafe";
import { PageTitle } from "components/PageTitle";
import { Link, Outlet, redirect, useSearchParams } from "react-router-dom";
import FormattedDate from "components/FormattedDate";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "api/api_client";
import { appointmentsApi } from "api/resources/appointments";
import { getTodayDate } from "utils/helpers";
import { appointmentsPath, joinAppointmentPagePath } from "utils/paths";
import { searchParamsToObject } from "utils/urlParams";

const columns: ColumnsType<Appointment> = [
  {
    title: "First Name",
    key: "first_name",
    render: (_, record) => record.user.first_name,
  },
  {
    title: "Last Name",
    key: "last_name",
    render: (_, record) => record.user.last_name,
  },
  {
    title: "Email",
    key: "email",
    render: (_, record) => record.user.email,
  },
  {
    title: "Reason for Visit",
    dataIndex: "reason_code",
    key: "reason_code",
  },
  {
    title: "Date",
    dataIndex: "start_date",
    key: "date",
    render: (value) => (
      <FormattedDate
        value={value}
        options={{ day: "numeric", month: "short" }}
      />
    ),
  },
  {
    title: "Start Time",
    dataIndex: "start_date",
    key: "start_time",
    render: (value) => (
      <FormattedDate
        value={value}
        options={{
          hour: "numeric",
          minute: "numeric",
        }}
      />
    ),
  },
  {
    title: "End Time",
    dataIndex: "end_date",
    key: "end_time",
    render: (value) => (
      <FormattedDate
        value={value}
        options={{
          hour: "numeric",
          minute: "numeric",
        }}
      />
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Practitioner",
    dataIndex: "practitioner",
    key: "practitioner",
    render: (value) => value.name,
  },
  {
    title: "Join Code",
    dataIndex: "join_code",
    key: "join_code",
    render: (value, record) => {
      if (value) return value;
      const [canGenerate, reason] = canGenerateJoinCode(record);
      if (!canGenerate) return reason;

      return (
        <Button
          onClick={() =>
            appointmentsApi.getJoinCode.call(record.id).then(() =>
              queryClient.invalidateQueries({
                queryKey: appointmentsApi.baseQueryKey,
              })
            )
          }
        >
          Generate
        </Button>
      );
    },
  },
];

const canGenerateJoinCode = (record: Appointment) => {
  if (record.appointment_type != "appointment_telehealth")
    return [false, "Not telehealth"];
  if (record.status == "cancelled") return [false, "Cancelled"];

  const now = new Date().getTime();
  const oneHour = 60 * 60 * 1000;
  const start_date = new Date(record.start_date).getTime();
  const end_date = new Date(record.end_date).getTime();

  return [
    now > start_date - oneHour && now < end_date + oneHour,
    "Available 1 hour prior to start time",
  ];
};

const searchParamsToFilters = (searchParams: URLSearchParams) => {
  const { start_date, ...params } = searchParamsToObject(searchParams);
  return { start_date: start_date || getTodayDate(), ...params };
};

export namespace Appointments {
  export const loader = makeLoader(({ request }) => {
    const searchParams = new URL(request.url).searchParams;
    return queryClient.ensureQueryData(
      appointmentsApi.getList.query({
        filters: searchParamsToFilters(searchParams),
      })
    );
  });

  export const handle = {
    crumb: () => <Link to={appointmentsPath}>Appointments</Link>,
  };

  export const Element = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialData = useLoaderData<typeof loader>();
    const filters = useMemo(
      () => searchParamsToFilters(searchParams),
      [searchParams]
    );
    const {
      data: { data: appointments, meta },
    } = useQuery({
      ...appointmentsApi.getList.query({ filters }),
      initialData,
      staleTime: 2 * 60 * 1000,
    });

    return (
      <>
        <PageTitle title="Appointments"></PageTitle>
        <Descriptions
          items={[
            {
              key: "date",
              label: "Date",
              children: (
                <>
                  <FormattedDate value={filters.start_date} />{" "}
                  {searchParams.get("start_date") ? (
                    ""
                  ) : (
                    <span
                      style={{
                        marginLeft: "1ch",
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      (Today)
                    </span>
                  )}
                </>
              ),
            },
            {
              key: "url",
              label: "URL",
              children: (
                <a
                  href={joinAppointmentPagePath}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {joinAppointmentPagePath}
                </a>
              ),
            },
          ]}
        />
        <Table
          columns={columns}
          dataSource={appointments}
          size="middle"
          pagination={{
            size: "default",
            pageSize: meta?.page_size,
            current: meta?.current_page,
            showTotal: (total) => `Total ${total}`,
            showSizeChanger: true,
            total: meta?.total_count,
            onChange(page, pageSize) {
              setSearchParams({
                page: page.toString(),
                per: pageSize.toString(),
              });
            },
          }}
          rowKey="id"
        />

        <Outlet />
      </>
    );
  };

  export const element = <Element />;
}

type FiltersProps = {};

const Filters = ({}: FiltersProps) => {
  return (
    <>
      <Form.Item
        label="Date"
        name="date"
        style={{ width: "100%", marginRight: "10px" }}
      >
        <DatePicker />
      </Form.Item>
    </>
  );
};

export default Appointments;
