import * as React from "react";
import { createRoutesFromElements, redirect } from "react-router";
import { createBrowserRouter, Link, Route } from "react-router-dom";
import * as paths from "utils/paths";
import AppLayout from "./layouts/AppLayout";
import Login from "./pages/Login";
import { NewTherapyGroup, TherapyGroups } from "pages/therapy_groups";
import { ManageTherapyGroup } from "pages/therapy_groups/ManageTherapyGroup";
import { NewEducationAssignment } from "pages/therapy_groups/manage_page_components/NewEducationAssignment";
import { Appointments } from "pages/appointments";
import NotificationEvents from "pages/notification_events/NotificationEvents";
import {
  GroupAppointments,
  NewGroupAppointmentForm,
} from "pages/group_appointments";
import { ManageGroupAppointment } from "pages/group_appointments/ManageGroupAppointment";
import { ManageUser, Users } from "pages/users";
import { CalendarSyncInfos } from "pages/calendars";

const routes = createRoutesFromElements(
  <Route path="/">
    <Route {...AppLayout}>
      <Route index loader={() => redirect(paths.groupAppointmentsPath)} />

      <Route
        path="group_appointments"
        handle={{
          crumb: () => (
            <Link to={paths.groupAppointmentsPath}>Group Appointments</Link>
          ),
        }}
      >
        <Route index {...GroupAppointments} />
        <Route path=":id" {...ManageGroupAppointment} />
      </Route>

      <Route
        path="therapy_groups"
        handle={{
          crumb: () => (
            <Link to={paths.therapyGroupsPath}>Group Appointments</Link>
          ),
        }}
      >
        <Route path="" {...TherapyGroups}>
          <Route path="new" {...NewTherapyGroup} />
        </Route>
        <Route path=":id" {...ManageTherapyGroup}>
          <Route path="appointments/new" {...NewGroupAppointmentForm} />
          <Route path="education_assignments/new" {...NewEducationAssignment} />
        </Route>
      </Route>

      <Route path="appointments" {...Appointments} />
      <Route
        path="notification_events"
        handle={{
          crumb: () => (
            <Link to={paths.notificationEventsPath}>Notification Events</Link>
          ),
        }}
      >
        <Route index {...NotificationEvents} />
      </Route>

      <Route
        path="users"
        handle={{
          crumb: () => <Link to={paths.usersPath}>Users</Link>,
        }}
      >
        <Route index {...Users} />
        <Route path=":id" {...ManageUser} />
      </Route>

      <Route
        path="calendar_sync_infos"
        handle={{
          crumb: () => (
            <Link to={paths.calendarSyncInfosPath}>Calendar Syncs</Link>
          ),
        }}
      >
        <Route index {...CalendarSyncInfos} />
      </Route>
    </Route>
    <Route path="login" loader={Login.loader} />
  </Route>
);

export const router = createBrowserRouter(routes);
