import { apiFetcher } from "../api_client";
import { makeApiResource } from "api/makeApiResource";

const basePath = "/admin/notification_events";

export const notificationEventsApi = makeApiResource({
  baseQueryKey: ["notification_events"],
  getList: (queryParams?: any, _context?: any) =>
    apiFetcher<ApiDataResponse<NotificationEvent[]>>(basePath, { queryParams }),
  sendMessage: (payload: any, _context?: any) =>
    apiFetcher<{}>(`${basePath}/send_message`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }),
});
