import React from "react";
import { Form } from "antd";
import { FetcherWithComponents } from "react-router-dom";
import { SearchInput } from "components/form/SearchInput";
import { ResponseAlert } from "components/form/ResponseAlert";
import { useQuery } from "@tanstack/react-query";
import { educationsApi } from "api/resources/educations";
import { therapyGroupsApi } from "api/resources/therapyGroups";

type NewEducationAssignmentFormProps = {
  formId?: string;
  group: TherapyGroup;
  submitFetcher: FetcherWithComponents<any>;
};

export const NewEducationAssignmentForm = ({
  formId = "educationAssignmentsCreate",
  group,
  submitFetcher,
}: NewEducationAssignmentFormProps) => {
  const {
    data: { data: therapyGroup },
  } = useQuery({
    ...therapyGroupsApi.get.query(group.id),
    initialData: { data: group },
  });

  const onFinish = (values: any) => {
    submitFetcher.submit(
      {
        formId,
        education_assignment: {
          ...values,
          education_assignments_users_attributes: therapyGroup.members.map(
            (m) => ({ user_id: m.user_id })
          ),
          therapy_group_id: therapyGroup.id,
        },
      },
      {
        method: "POST",
        encType: "application/json",
      }
    );
  };

  return (
    <Form
      name={formId}
      style={{ marginTop: "20px" }}
      layout="vertical"
      onFinish={onFinish}
      requiredMark="optional"
    >
      <Form.Item
        label="Education"
        name="education_id"
        rules={[{ required: true }]}
      >
        <SearchInput
          queryOptionsFn={(title) =>
            educationsApi.getList.query({ filters: { title } })
          }
          paramsMethod={(e) => ({ label: e.title, value: e.id })}
        />
      </Form.Item>

      {submitFetcher.data && <ResponseAlert response={submitFetcher.data} />}
    </Form>
  );
};
