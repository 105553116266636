import { apiFetcher } from "../api_client";
import { makeApiResource } from "api/makeApiResource";

const basePath = "/admin/session";

export const sessionsApi = makeApiResource({
  baseQueryKey: ["practitioners"],
  create: (_context?: any) =>
    apiFetcher<ApiDataResponse<AuthData>>(`${basePath}`, {
      method: "POST",
      credentials: "include",
    }),
});
