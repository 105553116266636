import * as React from "react";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { FormInstance, useForm, useWatch } from "antd/es/form/Form";
import { FetcherWithComponents, useFetcher } from "react-router-dom";
import { SearchInput } from "components/form/SearchInput";
import { ResponseAlert } from "components/form/ResponseAlert";
import { practitionersApi } from "api/resources/practitioners";
import { prepareValuesForApi } from "utils/helpers";
import { locationsApi } from "api/resources/locations";
import { useQuery } from "@tanstack/react-query";
import { appointmentSlotsApi } from "api/resources/appointmentSlots";
import { usersApi } from "api/resources/users";
import { therapyGroupsApi } from "api/resources/therapyGroups";
import { groupAppointmentsPath } from "utils/paths";

type NewGroupAppointmentProps = {
  formId?: string;
  initialValues?: any;
  initialUsersSearchData?: any;
  submitFetcher: FetcherWithComponents<any>;
};

export const NewGroupAppointmentForm = ({
  formId = "groupAppointmentCreate",
  initialValues,
  initialUsersSearchData,
  submitFetcher,
}: NewGroupAppointmentProps) => {
  const [form] = useForm();
  const selectedDuration = useWatch("duration", form);
  const selectedDate = useWatch("date", form);
  const selectedPractitioner = useWatch("practitioner_id", form);
  const { data: groupData } = useQuery({
    ...therapyGroupsApi.get.query(initialValues?.group?.id!),
    enabled: !!initialValues?.group?.id,
  });

  const appointmentSlots = useQuery({
    ...appointmentSlotsApi.getList.query({
      date: selectedDate?.format("YYYY-MM-DD"),
      duration: selectedDuration,
      practitioner_id: selectedPractitioner,
    }),
    enabled: !!(selectedDuration && selectedDate && selectedPractitioner),
    staleTime: 2 * 60 * 1000,
  });

  const onFinish = (values: any) => {
    values.user_appointments = values.participants?.map((id: string) => ({
      user_id: id,
      status: "proposed",
    }));
    values.group_id = initialValues?.group?.id;
    values.group.id = initialValues?.group?.id;
    values.group.facilitator_id = values.practitioner_id;

    const new_members = values.participants?.filter((id: string) => {
      return !groupData?.data?.members?.find((m: any) => m.user_id === id);
    });

    values.group.members_attributes =
      new_members?.map((id: string) => ({
        user_id: id,
      })) || [];
    values.group.id = initialValues?.group?.id;
    delete values.participants;
    delete values.date;
    delete values.duration;
    values.end_date = new Date(
      Date.parse(values.start_date) + selectedDuration * 60 * 1000
    ).toISOString();

    submitFetcher.submit(
      {
        // formId,
        therapy_group_appointment: {
          ...prepareValuesForApi(values, {
            nestedAttributes: ["group", "user_appointments"],
          }),
        },
      },
      {
        action: `${groupAppointmentsPath}?index`,
        method: "POST",
        encType: "application/json",
        navigate: true,
      }
    );
  };

  return (
    <Form
      name={formId}
      style={{ marginTop: "20px" }}
      labelAlign="left"
      form={form}
      onFinish={onFinish}
      colon={false}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      initialValues={initialValues}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            label="Date"
            name="date"
            labelCol={{ span: 8 }}
            rules={[{ required: true }]}
            style={{ width: "100%", marginRight: "10px" }}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Practitioner"
            name="practitioner_id"
            labelCol={{ span: 8 }}
            rules={[{ required: true }]}
          >
            <SearchInput
              queryOptionsFn={(name) =>
                practitionersApi.getList.query({ filters: { name } })
              }
              paramsMethod={(p) => ({ label: p.name, value: p.id })}
            />
          </Form.Item>

          <Form.Item
            label="Type"
            name="appointment_type"
            labelCol={{ span: 8 }}
          >
            <Select
              options={[
                { label: "Appointment", value: "appointment" },
                {
                  label: "Appointment Telehealth",
                  value: "appointment_telehealth",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Duration"
            name="duration"
            labelCol={{ span: 8 }}
            rules={[{ required: true }]}
            style={{ width: "100%" }}
          >
            <Select
              style={{ width: "100%" }}
              options={[
                { label: "60 minutes", value: 60 },
                { label: "90 minutes", value: 90 },
                { label: "120 minutes", value: 120 },
                { label: "150 minutes", value: 150 },
                { label: "180 minutes", value: 180 },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Time slot"
            name="start_date"
            labelCol={{ span: 8 }}
            rules={[{ required: true }]}
          >
            <Select
              disabled={
                !!(!selectedDuration || !selectedDate || !selectedPractitioner)
              }
              loading={appointmentSlots.isFetching}
              options={appointmentSlots.data?.data.map((slot, index) => ({
                label: appointmentSlotToLabel(slot),
                value: slot.start,
                key: index,
              }))}
            />
          </Form.Item>

          <Form.Item name={["group", "id"]} hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label="Location"
            name={["group", "location_id"]}
            labelCol={{ span: 8 }}
            rules={[{ required: true }]}
          >
            <SearchInput
              queryOptionsFn={() => locationsApi.getList.query()}
              paramsMethod={(location) => ({
                label: location.name,
                value: location.id,
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Group Type"
        name={["group", "group_type"]}
        rules={[{ required: true }]}
      >
        <Select
          options={[
            "Detox",
            "Psychoeducational",
            "Therapeutic",
            "Relapse Prevention",
            "Skills",
            "Processing",
            "Family",
            "Peer",
            "Other",
          ].map((value) => ({ label: value, value }))}
        />
      </Form.Item>

      <Form.Item
        label="Level of Care"
        name={["group", "level_of_care"]}
        rules={[{ required: true }]}
      >
        <Select
          options={[
            "Detoxification",
            "High Intensity Outpatient",
            "Intensive Outpatient",
            "Outpatient",
            "Aftercare",
            "Other",
          ].map((value) => ({ label: value, value }))}
        />
      </Form.Item>

      <Form.Item label="Topic" name="description">
        <Input />
      </Form.Item>

      <Form.Item label="Participants" name={["participants"]}>
        <SearchInput
          mode="multiple"
          queryOptionsFn={(name) =>
            usersApi.getList.query({ filters: { name } })
          }
          additionalOptions={initialUsersSearchData?.data?.map((u: any) => ({
            label: `${u.first_name} ${u.last_name} (${u.birthdate})`,
            value: u.id,
          }))}
          paramsMethod={(u) => ({
            label: `${u.first_name} ${u.last_name} (${u.birthdate})`,
            value: u.id,
          })}
        />
      </Form.Item>

      {submitFetcher.data?.errors && (
        <ResponseAlert response={submitFetcher.data} />
      )}
    </Form>
  );
};

const parseDate = (date: string) =>
  new Date(Date.parse(date)).toLocaleString(undefined, {
    timeStyle: "short",
  });

const appointmentSlotToLabel = ({ start, end }: AppointmentSlot) => {
  return (
    <Row gutter={12}>
      <Col span={10} style={{ textAlign: "right" }}>
        {parseDate(start)}
      </Col>
      <Col span={4} style={{ textAlign: "center" }}>
        -
      </Col>
      <Col span={10} style={{ textAlign: "right" }}>
        {parseDate(end)}
      </Col>
    </Row>
  );
};
