import { apiFetcher } from "../api_client";
import { makeApiResource } from "api/makeApiResource";

const basePath = "/admin/locations";

export const locationsApi = makeApiResource({
  baseQueryKey: ["locations"],
  getList: (queryParams?: any, _context?: any) =>
    apiFetcher<ApiDataResponse<CanvasLocation[]>>(basePath, { queryParams }),
});
