import React, { useState } from "react";
import { Button, Flex } from "antd";
import { SearchInput } from "components/form/SearchInput";
import { useFetcher } from "react-router-dom";
import { usersApi } from "api/resources/users";
import Table, { ColumnsType } from "antd/es/table";
import FormattedDate from "components/FormattedDate";

type TherapyGroupMembersFormProps = {
  therapyGroup: TherapyGroup;
};

const columns: ColumnsType<TherapyGroupMember> = [
  {
    title: "First Name",
    key: "first_name",
    render: (_, record) => record.user.first_name,
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    render: (_, record) => record.user.last_name,
  },
  {
    title: "Email",
    dataIndex: "email",
    render: (_, record) => record.user.email,
  },
  {
    title: "Member since",
    dataIndex: "created_at",
    render: (value) => <FormattedDate value={value} />,
  },
];

export const TherapyGroupMembers = ({
  therapyGroup,
}: TherapyGroupMembersFormProps) => {
  const submitFetcher = useFetcher();
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>();

  const onUsersAdd = () => {
    if (!selectedUserIds) return;
    submitFetcher.submit(
      {
        members_attributes: selectedUserIds.map((id) => ({ user_id: id })),
      },
      {
        method: "POST",
        encType: "application/json",
      }
    );
    setSelectedUserIds(undefined);
  };

  return (
    <Flex vertical gap="10px" style={{ width: "100%" }}>
      <Flex>
        <SearchInput
          value={selectedUserIds}
          queryOptionsFn={(name) => ({
            ...usersApi.getList.query({ filters: { name } }),
            staleTime: 2 * 60 * 1000,
          })}
          paramsMethod={(user) => ({
            label: `${user.first_name} ${user.last_name} ${user.email}`,
            disabled: !!therapyGroup.members.find((m) => m.user.id === user.id),
            value: user.id,
            // fullValue: user,
          })}
          mode="multiple"
          onChange={(value, _: any) => {
            setSelectedUserIds(value);
          }}
          // excludeOptions={(user) =>
          //   !!therapyGroup.members.find((m) => m.user.id === user.id)
          // }
          style={{ width: "100%" }}
        />
        <Button
          type="primary"
          style={{ float: "right", marginLeft: "24px" }}
          disabled={!selectedUserIds}
          onClick={(e) => {
            e.preventDefault();
            onUsersAdd();
          }}
        >
          Add new members
        </Button>
      </Flex>

      <Table
        columns={columns}
        dataSource={therapyGroup.members}
        rowKey={"id"}
        pagination={false}
        size="small"
      />
    </Flex>
  );
};
