import React from "react";
import { Button, Table, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import { makeAction, makeLoader, useLoaderData } from "react-router-typesafe";
import { PageTitle } from "components/PageTitle";
import { Link, useFetcher, useSearchParams } from "react-router-dom";
import FormattedDate from "components/FormattedDate";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "api/api_client";
import { calendarSyncInfosPath } from "utils/paths";
import { searchParamsToObject } from "utils/urlParams";
import { BooleanIcon } from "components/BooleanIcon";
import { googleCalendarSyncInfosApi } from "api/resources/google_calendar_sync_infos";
import { CalendarSyncForm } from "./CalendarSyncForm";
import { googleCalendarsApi } from "api/resources/google_calendars";
import { toast } from "react-toastify";
import { handleErrorResponse } from "utils/loaderHelpers";
import { useOnSubmit } from "hooks/useOnSubmit";

const columns: ColumnsType<GoogleCalendarSyncInfo> = [
  {
    title: "Source",
    key: "source",
    render: (_, record) =>
      record.source_calendar.name || record.source_calendar.calendar_id,
  },
  {
    title: "Target",
    key: "target",
    render: (_, record) =>
      record.target_calendar.name || record.target_calendar.calendar_id,
  },
  {
    title: "Active",
    key: "active",
    render: (_, record) => (
      <BooleanIcon
        value={
          record.source_calendar.channel_expires_at > new Date().toISOString()
        }
      />
    ),
  },
  {
    title: "Last Sync at",
    key: "last_sync_at",
    render: (_, record) => (
      <FormattedDate value={record.source_calendar.updated_at} />
    ),
  },
];

const CALENDAR_FORM_ID = "calendarCreate";
const CALENDAR_SYNC_FORM_ID = "calendarSyncCreate";

export namespace CalendarSyncInfos {
  export const loader = makeLoader(({ request }) => {
    const searchParams = new URL(request.url).searchParams;
    return queryClient.ensureQueryData(
      googleCalendarSyncInfosApi.getList.query(
        searchParamsToObject(searchParams)
      )
    );
  });

  export const action = makeAction(async ({ request, params }) => {
    const { formId, ...payload } = await request.json();
    switch (formId) {
      case CALENDAR_SYNC_FORM_ID:
        return googleCalendarSyncInfosApi.create
          .call(payload)
          .then((result) => {
            toast.success("Calendar sync created successfully");
            queryClient.invalidateQueries({
              queryKey: googleCalendarSyncInfosApi.baseQueryKey,
            });
            return result;
          })
          .catch(handleErrorResponse);
      case CALENDAR_FORM_ID:
        return googleCalendarsApi.create
          .call(payload)
          .then((result) => {
            toast.success("Calendar created successfully");
            queryClient.invalidateQueries({
              queryKey: googleCalendarsApi.baseQueryKey,
            });
            return result;
          })
          .catch(handleErrorResponse);
    }
  });

  export const handle = {
    crumb: () => <Link to={calendarSyncInfosPath}>Calendar Syncs</Link>,
  };

  export const Element = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialData = useLoaderData<typeof loader>();
    const {
      data: { data: syncInfos, meta },
    } = useQuery({
      ...googleCalendarSyncInfosApi.getList.query(
        searchParamsToObject(searchParams)
      ),
      initialData,
      staleTime: 2 * 60 * 1000,
    });

    const submitFetcher = useFetcher();
    const [createModalOpen, setCreateModalOpen] = React.useState(false);
    useOnSubmit(submitFetcher, (fetcher) => {
      setCreateModalOpen(false);
    });

    return (
      <>
        <PageTitle title="Calendar Syncs">
          <Button type="primary" onClick={() => setCreateModalOpen(true)}>
            Synchronize calendars
          </Button>
        </PageTitle>

        <Table
          columns={columns}
          dataSource={syncInfos}
          size="middle"
          pagination={{
            size: "default",
            pageSize: meta?.page_size,
            current: meta?.current_page,
            showTotal: (total) => `Total ${total}`,
            showSizeChanger: true,
            total: meta?.total_count,
            onChange(page, pageSize) {
              setSearchParams({
                page: page.toString(),
                per: pageSize.toString(),
              });
            },
          }}
          rowKey="id"
        />

        <Modal
          open={createModalOpen}
          title="Create a new calendar"
          okText="Create"
          okButtonProps={{
            autoFocus: true,
            htmlType: "submit",
            form: CALENDAR_SYNC_FORM_ID,
          }}
          onCancel={() => setCreateModalOpen(false)}
          destroyOnClose
        >
          <CalendarSyncForm
            submitFetcher={submitFetcher}
            formId={CALENDAR_SYNC_FORM_ID}
          />
        </Modal>
      </>
    );
  };

  export const element = <Element />;
}

export default CalendarSyncInfos;
