import { useEffect, useRef } from "react";

export const useOnChange = <T>(
  dependency: T,
  callback: (prevValue: T, curValue: T) => void
) => {
  const prevValue = useRef(dependency);

  return useEffect(() => {
    if (prevValue.current === dependency) return;
    callback(prevValue.current, dependency);
    prevValue.current = dependency;
  }, [dependency, callback]);
};
