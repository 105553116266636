import { apiFetcher } from "../api_client";
import { makeApiResource } from "api/makeApiResource";

const basePath = "/admin/appointment_slots";

export const appointmentSlotsApi = makeApiResource({
  baseQueryKey: ["appointment_slots"],
  getList: (queryParams?: any, _context?: any) =>
    apiFetcher<ApiDataResponse<AppointmentSlot[]>>(basePath, { queryParams }),
});
