import React from "react";
import { therapyGroupPath } from "utils/paths";
import { Link, Outlet, redirect, useParams } from "react-router-dom";
import Descriptions, { DescriptionsItemType } from "antd/es/descriptions";
import { PageTitle } from "components/PageTitle";
import { makeAction, makeLoader, useLoaderData } from "react-router-typesafe";
import { TherapyGroupAppointments } from "./manage_page_components/TherapyGroupAppointments";
import { toast } from "react-toastify";
import { handleErrorResponse } from "utils/loaderHelpers";
import { therapyGroupsApi } from "api/resources/therapyGroups";
import { queryClient } from "api/api_client";
import { useQuery } from "@tanstack/react-query";
import { therapyGroupAppointmentApi } from "api/resources/therapyGroupAppointments";
import { Col, Flex, Row, Space } from "antd";
import { TherapyGroupMembers } from "./manage_page_components/TherapyGroupMembers";
import { educationAssignmentsApi } from "api/resources/educationAssignments";
import { EducationAssignments } from "./manage_page_components/EducationAssignments";
import FormattedDate from "components/FormattedDate";

export const therapyGroupQuery = (params: any) =>
  therapyGroupsApi.get.query(params.id!);

export const groupAppointmentsQuery = (params: any) => ({
  ...therapyGroupAppointmentApi.getList.query({
    filters: { group_id: params.id! },
  }),
  initialData: { data: [] },
});

export const groupEducationAssignmentsQuery = (params: any) => ({
  ...educationAssignmentsApi.getList.query({
    filters: { therapy_group_id: params.id! },
  }),
  initialData: { data: [] },
});

export namespace ManageTherapyGroup {
  export const loader = makeLoader(({ params }) => {
    queryClient.prefetchQuery(groupAppointmentsQuery(params));
    queryClient.prefetchQuery(groupEducationAssignmentsQuery(params));
    return queryClient.ensureQueryData(therapyGroupQuery(params));
  });

  export const action = makeAction(async ({ request, params }) =>
    therapyGroupsApi.update
      .call(params.id!, await request.json())
      .then(() => {
        toast.success("Therapy Group updated");
        queryClient.invalidateQueries(therapyGroupQuery(params));
        return redirect(".");
      })
      .catch(handleErrorResponse)
  );

  export const handle = {
    crumb: ({ data }: ApiDataResponse<TherapyGroup>) => (
      <Link to={therapyGroupPath(data.id)}>{data.id}</Link>
    ),
  };

  export const Element = () => {
    const initialData = useLoaderData<typeof loader>();
    const params = useParams();
    const {
      data: { data: therapyGroup },
    } = useQuery({
      ...therapyGroupQuery(params),
      initialData,
      staleTime: 2 * 60 * 1000,
    });

    const descriptionItems: DescriptionsItemType[] = [
      { key: "id", label: "ID", children: therapyGroup.id },
      {
        key: "level_of_care",
        label: "Level of Care",
        children: therapyGroup.level_of_care,
      },
      {
        key: "facilitator",
        label: "Facilitator",
        children: therapyGroup.facilitator?.name,
      },
      {
        key: "Location",
        label: "Location",
        children: therapyGroup.location?.name,
      },
      {
        key: "created_at",
        label: "Created at",
        children: <FormattedDate value={therapyGroup.created_at} />,
      },
    ];

    return (
      <>
        <PageTitle title="Therapy Group" />
        <Descriptions items={descriptionItems} />

        <Space direction="vertical" style={{ width: "100%" }}>
          <Row>
            <Col span={24}>
              <TherapyGroupMembers therapyGroup={therapyGroup} />
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <TherapyGroupAppointments therapyGroup={therapyGroup} />
            </Col>
            <Col span={12}>
              <EducationAssignments therapyGroup={therapyGroup} />
            </Col>
          </Row>
        </Space>

        <Outlet />
      </>
    );
  };

  export const element = <Element />;
}
