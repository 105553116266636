import React from "react";
import { Button, Form, Modal, Space } from "antd";
import { FetcherWithComponents, useFetcher } from "react-router-dom";
import { SearchInput } from "components/form/SearchInput";
import { ResponseAlert } from "components/form/ResponseAlert";
import { useQuery } from "@tanstack/react-query";
import { useForm, useWatch } from "antd/es/form/Form";
import { googleCalendarsApi } from "api/resources/google_calendars";
import useDebounce from "hooks/useDebounce";
import { useOnChange } from "hooks/useOnChange";
import { CalendarForm } from "./CalendarForm";
import { useOnSubmit } from "hooks/useOnSubmit";

type CalendarSyncFormProps = {
  // form: FormInstance;
  submitFetcher: FetcherWithComponents<any>;
  formId?: string;
};

export const CalendarSyncForm = ({
  submitFetcher,
  formId = "calendarSyncCreate",
}: CalendarSyncFormProps) => {
  const calendarSubmitFetcher = useFetcher();
  const [form] = useForm();

  const [calendarModalOpen, setCalendarModalOpen] = React.useState(false);
  const [modalFor, setModalFor] = React.useState("source_calendar_id");

  useOnSubmit(calendarSubmitFetcher, (fetcher) => {
    form.setFieldValue(modalFor, fetcher.data.data.id);
    setCalendarModalOpen(false);
  });

  const onFinish = (values: any) => {
    submitFetcher.submit(
      {
        formId: formId,
        ...values,
      },
      {
        method: "POST",
        encType: "application/json",
      }
    );
  };

  return (
    <Form
      name={formId}
      id={formId}
      style={{ marginTop: "20px" }}
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <Form.Item label="Source Calendar">
        <Space.Compact direction="horizontal" block>
          <Form.Item
            style={{ width: "100%" }}
            name="source_calendar_id"
            rules={[{ required: true }]}
          >
            <SearchInput
              queryOptionsFn={(value) => googleCalendarsApi.getList.query({})}
              paramsMethod={(cal) => ({ label: cal.name, value: cal.id })}
            />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={() => {
                setCalendarModalOpen(true);
                setModalFor("source_calendar_id");
              }}
            >
              New Calendar
            </Button>
          </Form.Item>
        </Space.Compact>
      </Form.Item>

      <Form.Item label="Target Calendar">
        <Space.Compact direction="horizontal" block>
          <Form.Item
            style={{ width: "100%" }}
            name="target_calendar_id"
            rules={[{ required: true }]}
          >
            <SearchInput
              queryOptionsFn={(value) => googleCalendarsApi.getList.query({})}
              paramsMethod={(cal) => ({ label: cal.name, value: cal.id })}
            />
          </Form.Item>
          <Button
            onClick={() => {
              setCalendarModalOpen(true);
              setModalFor("target_calendar_id");
            }}
          >
            New Calendar
          </Button>
        </Space.Compact>
      </Form.Item>

      {submitFetcher.data?.errors && (
        <ResponseAlert response={submitFetcher.data} />
      )}

      <Modal
        open={calendarModalOpen}
        title="Create a new calendar"
        okText="Create"
        okButtonProps={{
          autoFocus: true,
          htmlType: "submit",
          form: "calendarCreate",
          loading: calendarSubmitFetcher.state === "loading",
        }}
        onCancel={() => setCalendarModalOpen(false)}
        destroyOnClose
      >
        <CalendarForm
          formId={"calendarCreate"}
          submitFetcher={calendarSubmitFetcher}
        />
      </Modal>
    </Form>
  );
};
