import React from "react";
import { groupEducationAssignmentsQuery } from "../ManageTherapyGroup";
import { useQuery } from "@tanstack/react-query";
import { Button, Flex, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import FormattedDate from "components/FormattedDate";

type EducationAssignmentsProps = {
  therapyGroup: TherapyGroup;
};

const columns: ColumnsType<EducationAssignment> = [
  {
    title: "Education",
    key: "education",
    render: ({ education }) => education.title,
  },
  {
    title: "Assigned at",
    key: "created_at",
    dataIndex: "created_at",
    render: (value) => <FormattedDate value={value} />,
  },
];

export const EducationAssignments = ({
  therapyGroup,
}: EducationAssignmentsProps) => {
  const {
    data: { data: educationAssignments },
    isFetching,
  } = useQuery({
    ...groupEducationAssignmentsQuery(therapyGroup),
    staleTime: 2 * 60 * 1000,
  });

  return (
    <Flex vertical align="flex-end" gap="10px" style={{ width: "100%" }}>
      <Link to="education_assignments/new">
        <Button type="primary">Assign Education</Button>
      </Link>

      <Table
        columns={columns}
        dataSource={educationAssignments}
        rowKey={({ id }) => id}
        loading={isFetching}
        style={{ width: "100%" }}
        pagination={false}
        size="small"
        virtual
      />
    </Flex>
  );
};
