import React from "react";

type DateFormat = "short" | "long" | "time";

type FormattedDateProps = {
  value?: string | null;
  options?: Intl.DateTimeFormatOptions;
  format?: DateFormat;
};

export const FormattedDate = ({
  value,
  options,
  format = "short",
}: FormattedDateProps) => {
  if (!value) return <span>-</span>;

  const date = new Date(value).toLocaleString(
    undefined,
    options || formatToOptions(format)
  );

  return <span>{date}</span>;
};

const formatToOptions = (format: DateFormat): Intl.DateTimeFormatOptions => {
  switch (format) {
    case "short":
      return {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
      };
    case "long":
      return {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
    case "time":
      return {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
  }
  return {};
};

export default FormattedDate;
